import { CircleBackground } from '../CircleBackground';
import { Container } from '../Container2';
import { motion } from 'framer-motion';
import ReactPixel from 'react-facebook-pixel';
import { useTranslation } from 'react-i18next';

export function CallToAction() {
    const containerVariants = {
        hidden: { opacity: 0, scale: 1 },
        visible: { opacity: 1, scale: 1, transition: { delay: 0, duration: 0.8 } },
    };

    const textVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { delay: 0.4, duration: 1 } },
    };

    const clickCta = () => {
        ReactPixel.track('ClickInquiry');
        window.location.href = '/inquiry';
    };

    const perWordVar = (delay: number) => {
        return {
            hidden: { opacity: 0, y: 20 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    delay: delay,
                    type: 'spring',
                    stiffness: 100,
                },
            },
        };
    };

    const AnimatedText = ({ text, delayPerWord = 0.1 }: { text: string; delayPerWord?: number }) => {
        const words = text.split(' ');
        return (
            <div>
                {words.map((word: any, index: any) => (
                    <motion.h2
                        style={{ display: 'inline-block', marginRight: '0.5rem' }}
                        variants={perWordVar(index * delayPerWord)}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{ once: true, amount: 0 }}>
                        <h2 className='text-3xl font-medium tracking-tight text-gray-900 sm:text-4xl'>{word}</h2>
                    </motion.h2>
                ))}
            </div>
        );
    };

    const AnimatedTextPTag = ({ text, delayPerWord = 0.1 }: { text: string; delayPerWord?: number }) => {
        const words = text.split(' ');
        return (
            <div>
                {words.map((word: any, index: any) => (
                    <motion.p
                        style={{ display: 'inline-block', marginRight: '0.3rem' }}
                        variants={perWordVar(index * delayPerWord)}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{ once: true, amount: 0 }}>
                        <p className='mt-4 text-lg leading-8 text-gray-400'>{word}</p>
                    </motion.p>
                ))}
            </div>
        );
    };

    const { t } = useTranslation();

    return (
        <motion.section
            className='relative overflow-hidden bg-white'
            variants={containerVariants}
            initial='hidden'
            animate='visible'>
            <section className='relative overflow-hidden bg-white py-20 sm:py-28'>
                <div className='absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2'>
                    <CircleBackground color='#0f0f0f' className='animate-spin-slow' />
                </div>
                <Container className='relative'>
                    <div className='mx-auto max-w-md sm:text-center'>
                        <AnimatedText text={t('index.still_hesitating')} delayPerWord={0.08} />
                        <motion.p
                            className='mt-4 text-lg text-gray-500'
                            variants={textVariants}
                            initial='hidden'
                            animate='visible'>
                            <p className='mt-4 text-lg text-gray-500'>{t('index.still_hesitating_sub')}</p>
                        </motion.p>
                        <a onClick={clickCta}>
                            <div className='flex sm:justify-center'>
                                <div className='mt-8 relative rounded-full px-3 py-1 text-sm leading-6 text-gray-900 ring-1 ring-black/80 hover:ring-gray-400 transition-all ease-in-out duration-150 shadow'>
                                    {t('index.cta')}{' '}
                                    <a href='/inquiry' className='font-semibold'>
                                        <span className='absolute inset-0' aria-hidden='true' />
                                        <span aria-hidden='true'>&rarr;</span>
                                    </a>
                                </div>
                            </div>
                        </a>
                    </div>
                </Container>
            </section>
        </motion.section>
    );
}

export default CallToAction;
