import React from 'react';
import { Container } from './Container';
import { BsHddNetwork, BsShieldShaded, BsShieldLock, BsSave } from 'react-icons/bs';
import 'tailwindcss/tailwind.css';
import { Union, tw } from '../Utils';
import { useStrictMediaQuery } from '../Utils/hooks';
import { IChildrenProps, IChildrenPropsWithClassName } from '../Interfaces';
import { Link } from './Buttons';
import { SocialIcon } from 'react-social-icons';
import { useTranslation } from 'react-i18next';

interface IFooterGroup extends IChildrenPropsWithClassName {}

const footerInfoGroup = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    fontSize: 'text-sm',
    justifyContent: 'justify-between',
    borderBottomWidth: 'border-b',
    paddingBottom: 'pb-4',
    marginBottom: 'mb-6',
    color: 'text-gray-500',
});

const FooterInfoGroup = ({ children }: IChildrenProps) => {
    return <div className={footerInfoGroup.class}>{children}</div>;
};

const footerGroup = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    marginRight: 'mr-4',
    marginLeft: 'ml-2',
    marginBottom: 'mb-3',
    minWidth: 'min-w-[135px]',
    fontSize: 'text-sm',
    '@max-sm': {
        marginRight: 'max-sm:mr-2',
    },
});

const FooterGroup = ({ className, children }: IFooterGroup) => {
    return <div className={Union(footerGroup.class, className)}>{children}</div>;
};

interface IFooterText {
    text: string;
}

const footerTextTitle = tw.style({
    fontSize: 'text-base',
    fontWeight: 'font-bold',
    marginBottom: 'mb-2',
    color: 'text-gray-700',
});

const FooterTextTitle = ({ text }: IFooterText) => {
    return <span className={footerTextTitle.class}>{text}</span>;
};

interface IFooterTextLink extends IFooterText {
    link?: string;
    externalLink?: string;
    onClick?: () => void;
}

const footerText = tw.style({
    marginBottom: 'mb-1',
    color: 'text-gray-500',
});

const FooterText = ({ text, externalLink, link, onClick }: IFooterTextLink) => {
    return (
        <a className={footerText.class} href={externalLink} onClick={onClick}>
            <Link to={link}>{text}</Link>
        </a>
    );
};

const footerLastInfoGroup = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    width: 'w-full',
    fontSize: 'text-sm',
    float: 'float-right',
    color: 'text-gray-500',
    borderTopWidth: 'border-t',
    paddingTop: 'pt-4',
    marginTop: 'mt-2',
});

const FooterLastInfoGroup = ({ children }: IChildrenProps) => {
    return <div className={footerLastInfoGroup.class}>{children}</div>;
};

interface IFooter {
    children?: React.ReactNode;
}

const Footer = ({ children }: IFooter) => {
    const { t } = useTranslation();
    return (
        <div className='flex flex-row items-center bg-gray-50 py-5 overflow-hidden md:px-24 max-md:px-4 w-full shrink-0 flex-grow justify-center'>
            <Container>
                {children && <FooterInfoGroup>{children}</FooterInfoGroup>}
                <div className='flex flex-row'>
                    <div>
                        <FooterGroup className='float-left'>
                            <FooterTextTitle text={t('footer.sales')} />
                            <FooterText text={t('footer.inquiry')} link='/inquiry#top' />
                            <FooterText text={t('footer.partnership')} link='/partnership/request#top' />
                        </FooterGroup>
                        <FooterGroup className='float-left'>
                            <FooterTextTitle text={t('footer.service_info')} />
                            <FooterText text={t('footer.about_phylaxis')} link='/about#top' />
                            <FooterText text={t('footer.technical_details')} link='/waap/about#caching' />
                            <FooterText text={t('footer.waap')} link='/waap/about#WAF' />
                        </FooterGroup>
                    </div>
                    <div>
                        <FooterGroup className='float-left'>
                            <FooterTextTitle text={t('footer.get_started')} />
                            <FooterText text={t('footer.pricing')} link='/waap/pricing#top' />
                        </FooterGroup>
                        <FooterGroup className='float-left'>
                            <FooterTextTitle text={t('footer.information')} />
                            <FooterText text={t('footer.privacy_policy')} link='/privacy' />
                            <FooterText text={t('footer.terms_of_use')} link='/terms' />

                            {/* <FooterText text="개인정보처리방침" link="/privacy-policy" />
              <FooterText text="이용 약관" link="/terms" /> */}
                            <FooterText text={t('footer.support')} link='https://phylaxis.channel.io/home' />
                            <FooterText text={t('footer.sla_policy')} link='/sla' />
                        </FooterGroup>
                    </div>
                    <div>
                        <FooterGroup className='float-left'>
                            <FooterTextTitle text={t('footer.pages')} />
                            <FooterText
                                text={t('footer.social_icons.official_blog')}
                                link='https://blog.phylaxis.net/'
                            />
                            <FooterText
                                text={t('footer.social_icons.naver_blog')}
                                link='https://blog.naver.com/phylaxis_inc'
                            />
                            <FooterText
                                text={t('footer.social_icons.discord')}
                                link='https://disquiet.io/team/phylaxis'
                            />
                            <FooterText text={t('footer.social_icons.github')} link='https://github.com/PHYLAXIS-NET' />
                            <FooterText
                                text={t('footer.social_icons.linkedin')}
                                link='https://www.linkedin.com/company/phylaxis-net/'
                            />
                            <FooterText text={t('footer.social_icons.disquiet')} link='https://discord.gg/ms3h2g22kG' />
                        </FooterGroup>
                    </div>
                    <div className='flex justify-center max-sm:hidden ml-auto'>
                        <FooterGroup className='float-right justify-center'>
                            <div className='flex justify-end py-2'>
                                <img src='/img/LogoAllGray.svg' alt='logo' className='w-24' />
                            </div>
                            <div className='flex flex-col'>
                                <span className='text-gray-700 text-right'>operated at</span>
                                <span className='text-phl-secondary-2 text-right'>Seoul headquarters</span>
                            </div>
                            <div className='mt-2 grid grid-cols-4 gap-2'>
                                <SocialIcon
                                    url='https://discord.gg/ms3h2g22kG'
                                    bgColor='#4b5563'
                                    style={{ height: 30, width: 30 }}
                                />
                                <SocialIcon
                                    url='https://github.com/PHYLAXIS-NET'
                                    bgColor='#4b5563'
                                    style={{ height: 30, width: 30 }}
                                />
                                <SocialIcon
                                    url='https://www.linkedin.com/company/phylaxis-net/'
                                    bgColor='#4b5563'
                                    style={{ height: 30, width: 30 }}
                                />
                                <SocialIcon
                                    url='https://disquiet.io/team/phylaxis'
                                    bgColor='#4b5563'
                                    style={{ height: 30, width: 30 }}
                                />
                                <SocialIcon
                                    url='https://blog.naver.com/phylaxis_inc'
                                    bgColor='#4b5563'
                                    style={{ height: 30, width: 30 }}
                                />
                            </div>
                        </FooterGroup>
                    </div>
                </div>
                <FooterLastInfoGroup>
                    {/* //blue circle with pulse animation and the text "all things are operational" */}
                    <a
                        href='https://www.smes.go.kr/venturein/pbntc/searchVntrCmp?cmpNm=%ED%95%84%EB%9D%BD%EC%8B%9C%EC%8A%A4&pageNo=1&relParam=%3FcmpNm%3D%ED%95%84%EB%9D%BD%EC%8B%9C%EC%8A%A4%26pgNo%3D1'
                        target='_blank'
                        className='flex justify-center items-start w-28'>
                        <img src='/img/venture_horizontal.png' alt='logo' className='w-28 mb-5' />
                    </a>
                    <div className='flex flex-row justify-left items-center mb-2'>
                        <iframe
                            src='https://status.phylax.is/embed-status/60bd687c/light-sm'
                            width='230'
                            height='61'
                            frameBorder='0'
                            scrolling='no'></iframe>
                    </div>

                    <span className='mb-2'>{t('footer.copyright')}</span>
                    <span className='text-gray-400'>{t('footer.location')}</span>
                    <span className='text-gray-400'>{t('footer.location_us')}</span>
                    <span className='text-gray-400 mb-2'>{t('footer.business_registration')}</span>
                    <span className='text-gray-400'>{t('footer.contact')}</span>
                    <span className='text-gray-400'>{t('footer.phone')}</span>
                    {/* <span className='text-gray-400 mb-2'>
                        사업자등록번호: 823-87-03089 | 통신판매업신고번호: 2023-서울중구-1551
                    </span>
                    <span className='text-gray-400'>이메일: corp@phylaxis.net | 필락시스(주) | 대표: 한상엽</span>
                    <span className='text-gray-400'>전화번호: 070-7666-9966 / 070-7666-9989</span> */}
                </FooterLastInfoGroup>
            </Container>
        </div>
    );
};

export default Footer;
