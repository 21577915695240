import { motion, Variants } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const variants: Variants = {
    offscreen: {
        y: 100,
        opacity: 0,
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: 'spring',
            bounce: 0.1,
            duration: 0.8,
        },
    },
};

const perWordVar = (delay: number) => {
    return {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: delay,
                type: 'spring',
                stiffness: 100,
            },
        },
    };
};

const AnimatedText = ({ text, delayPerWord = 0.1 }: { text: string; delayPerWord?: number }) => {
    const words = text.split(' ');
    return (
        <div>
            {words.map((word: any, index: any) => (
                <motion.h2
                    style={{ display: 'inline-block', marginRight: '0.5rem' }}
                    variants={perWordVar(index * delayPerWord)}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{ once: true, amount: 0 }}>
                    <h2 className='text-3xl font-bold tracking-tight text-gray-900'>{word}</h2>
                </motion.h2>
            ))}
        </div>
    );
};

const AnimatedTextPTag = ({ text, delayPerWord = 0.1 }: { text: string; delayPerWord?: number }) => {
    const words = text.split(' ');
    return (
        <div>
            {words.map((word: any, index: any) => (
                <motion.p
                    style={{ display: 'inline-block', marginRight: '0.3rem' }}
                    variants={perWordVar(index * delayPerWord)}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{ once: true, amount: 0 }}>
                    <p className='mt-4 text-lg leading-8 text-gray-400'>{word}</p>
                </motion.p>
            ))}
        </div>
    );
};

export default function Example() {
    const { t } = useTranslation();
    const stats = [
        { id: 1, name: t('index.domain_connected'), value: t('index.domain_connected_stat') },
        { id: 2, name: t('index.mitigated_requests'), value: t('index.mitigated_requests_stat') },
        { id: 3, name: t('index.uptime'), value: t('index.uptime_stat') },
        { id: 4, name: t('index.average_requests'), value: t('index.average_requests_stat') },
    ];
    return (
        <div className='bg-white py-24 sm:py-32'>
            <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                <div className='mx-auto max-w-2xl lg:max-w-none'>
                    <div className='text-center'>
                        <AnimatedText text={t('index.being_essential')} />
                        <motion.p initial='offscreen' whileInView='onscreen' variants={variants}>
                            <p className='mt-4 text-lg leading-8 text-gray-400'>{t('index.essential_sub')}</p>
                        </motion.p>
                    </div>
                    <motion.dl initial='offscreen' whileInView='onscreen' variants={variants}>
                        <dl className='mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4'>
                            {stats.map((stat) => (
                                <div key={stat.id} className='flex flex-col bg-black/5 p-8'>
                                    <dt className='text-sm font-semibold leading-6 text-gray-400'>{stat.name}</dt>
                                    <dd className='order-first text-3xl font-semibold tracking-tight text-gray-900'>
                                        {stat.value}
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </motion.dl>
                </div>
            </div>
        </div>
    );
}
