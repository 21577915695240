import React, { useState, useRef } from "react";
import Navigation from "../Components/Navigation/Navigation";
import NotFoundContent from "../Components/404Content";
import { useStrictMediaQuery } from "../Utils/hooks";
import Footer from "../Components/Footer";
import Hero from "../Components/PartnershipContents/Hero";
import LogoCloud from "../Components/PartnershipContents/LogoCloud";
import Information from "../Components/PartnershipContents/Information";

const RequestPartnership = () => {
  return (
    <>
      <a id="top" />
      <Navigation />
      <Hero />
      <LogoCloud />
      <Information />
      <Footer />
    </>
  );
};

export default RequestPartnership;
