import React, { useState, useRef } from "react";
import Navigation from "../Components/Navigation/Navigation";
import Content from "../Components/IntroContents/Content";
import { useStrictMediaQuery } from "../Utils/hooks";
import Footer from "../Components/Footer";
import AnnouncementDetail from "../Components/Announcement/Detail";

const IrDefault = () => {
  return (
    <>
      <a id="top" />
      <Navigation />
      <AnnouncementDetail />
      <Footer />
    </>
  );
};

export default IrDefault;