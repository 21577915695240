import React from "react";
import { useStrictMediaQuery } from "../Utils/hooks";
import "tailwindcss/tailwind.css";
import { Union, tw } from "../Utils";

export const NavContainer = ({ children }: any) => {
  return <div className={Union("container", "mx-auto")}>{children}</div>;
};

const container = tw.style({
  "@max-sm": {
    paddingX: "max-sm:px-0",
  },
  "@sm": {
    paddingX: "sm:px-0",
  },
  "@md": {
    paddingX: "md:px-3",
  },
  "@lg": {
    paddingY: "lg:py-4",
    paddingX: "lg:px-2",
  },
  "@xl": {
    container: "xl:container",
    marginX: "xl:mx-auto",
    padding: "xl:p-6",
  },
});

export const Container = ({ children }: any) => {
  return <div className={container.class}>{children}</div>;
};
