export default function Hero() {
    return (
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <p className="text-base font-semibold leading-7 text-phl-secondary-1">Announcements</p>
                        <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">공지사항</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            필락시스 주식회사의 최신 정보를 확인하실 수 있습니다.
                        </p>
                    </div>
                </div>
            </div>
    )
}