import React from 'react';
import { Container } from '../Container';
import 'tailwindcss/tailwind.css';
import { useStrictMediaQuery } from '../../Utils/hooks';
import { Union, tw } from '../../Utils';
import { IChildrenProps } from '../../Interfaces';
import { ButtonGroup, ButtonSecondary } from '../Buttons';
import { Text } from '../Texts';

const contentOuter = tw.style({
    width: 'w-full',
    overflow: 'overflow-hidden',
    paddingBottom: 'pb-16',
    padding: 'p-6',
});

const flexContainer = tw.style({
    display: 'flex',
    flexDirection: 'flex-row',
    textAlign: 'text-left',
    marginY: 'my-14',
    '@max-sm': {
        marginY: 'max-sm:my-8',
    },
    ':last': {
        marginBottom: 'last:mb-0',
    },
});

const imageContainer = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    textAlign: 'text-left',
});

const imageBox = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    justifyContent: 'justify-center',
    width: 'w-full',
    height: 'h-[400px]',
    marginTop: 'mt-10',
    '@max-sm': {
        marginTop: 'max-sm:mt-0',
    },
});

const textBox = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    justifyContent: 'justify-center',
    width: 'w-full',
    '@max-sm': {
        textAlign: 'max-sm:text-center',
    },
    '@sm': {
        marginX: 'sm:mx-6',
    },
    '@md': {
        width: 'md:w-full',
    },
    '@lg': {
        width: 'lg:w-full',
    },
});

const bigTitle = tw.style({
    paddingTop: 'pt-10',
    fontSize: 'text-4xl',
    fontWeight: 'font-semibold',
    color: 'text-phl-primary',
    // marginBottom: "mb-6",
    width: 'w-full',
    textAlign: 'text-center',
    '@max-sm': {
        fontSize: 'max-sm:text-4xl',
        marginBottom: 'max-sm:mb-4',
    },
});

const contentBg = tw.style({
    backgroundColor: 'bg-slate-100',
});

const contentBg2 = tw.style({
    backgroundColor: 'bg-slate-200',
});

const highlightText = tw.style({
    color: 'text-phl-secondary-1',
    fontWeight: 'font-extrabold',
});

const Content = () => {
    return (
        <>
            <div className={contentBg.class}>
                <div className={bigTitle.class}>
                    <span className='border-b border-b-phl-primary-dim px-2 pb-4'>
                        <strong>Our Roadmap</strong>
                    </span>
                </div>
                <div className={contentOuter.class}>
                    <Container>
                        <div className={flexContainer.class}>
                            <div className={textBox.class}>
                                <Text size='lg'>
                                    <p className={highlightText.class}>필락시스의 로드맵,</p> 성장과 발전.
                                </Text>
                                <Text size='md' style={tw.style({ fontSize: 'text-xl' })}>
                                    <span className='font-semibold'>2023년 7월</span> - 법인 설립 인가
                                    <br />
                                    <span className='font-semibold'>2023년 7월</span> - 판테라 이스포츠 파트너십 체결
                                    <br />
                                    <span className='font-semibold'>2023년 8월</span> - 스텔라아이티 주식회사 파트너십
                                    체결
                                    <br />
                                    <span className='font-semibold'>2023년 8월</span> - 사업자 등록
                                    <br />
                                    <span className='font-semibold'>2023년 9월</span> - 중소벤처기업진흥공단 지원업체
                                    선정
                                    <br />
                                    <span className='font-semibold'>2023년 9월</span> - 망 식별번호 (AS151810) 취득
                                    <br />
                                    <span className='font-semibold'>2023년 9월</span> - IPv4 512개 할당
                                    <br />
                                    <span className='font-semibold'>2023년 9월</span> - PacketStream 파트너십 체결
                                    <br />
                                    <span className='font-semibold'>2024년 2월</span> - Phylaxis WAAP v0.1 CBT
                                    <br />
                                    <span className='font-semibold'>2024년 3월</span> - 북미 지역 리전 확장
                                    <br />
                                    <span className='font-semibold'>2024년 4월</span> - Phylaxis WAAP v1.0 정식 배포
                                    <br />
                                    <span className='font-semibold'>2024년 4월</span> - 혁신성장유형 벤처인증 취득
                                    <br />
                                </Text>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default Content;
