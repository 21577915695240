import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Popover, Transition, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import {
    Bars3Icon,
    GlobeAltIcon,
    CubeTransparentIcon,
    FingerPrintIcon,
    SquaresPlusIcon,
    XMarkIcon,
    ArrowsPointingOutIcon,
    ServerStackIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon, ChatBubbleLeftIcon, DocumentIcon, RectangleGroupIcon } from '@heroicons/react/20/solid';
import { motion } from 'framer-motion';
import i18n from '../../locales/i18n';
import { useTranslation } from 'react-i18next';

const callsToAction = [
    { name: '도움문서', href: 'https://docs.phylaxis.net', icon: DocumentIcon, stat: 'production' },
    { name: '영업팀 문의', href: '/inquiry', icon: ChatBubbleLeftIcon, stat: 'production' },
    { name: '성공 사례', href: 'https://blog.phylaxis.net/case', icon: RectangleGroupIcon, stat: 'production' },
];

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
};

const varMotion = (delay: number) => {
    return {
        hidden: { opacity: 0, y: -10 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: delay,
                type: 'spring',
                stiffness: 100,
            },
        },
    };
};

export default function Example() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { t } = useTranslation();

    const products = [
        {
            name: t('navigation.products.phylaxis_waap'),
            description: t('navigation.products.waap_description'),
            stat: 'production',
            href: 'https://waap.phylaxis.net',
            icon: GlobeAltIcon,
        },
        {
            name: t('navigation.products.phylaxis_compute'),
            description: t('navigation.products.compute_description'),
            stat: 'production',
            href: '/inquiry',
            icon: ServerStackIcon,
        },
        {
            name: t('navigation.products.colocation'),
            description: t('navigation.products.colocation_description'),
            stat: 'production',
            href: '/inquiry',
            icon: ArrowsPointingOutIcon,
        },
        {
            name: t('navigation.products.phylaxis_pages'),
            description: t('navigation.products.pages_description'),
            stat: 'beta',
            href: '#',
            icon: CubeTransparentIcon,
        },
        {
            name: t('navigation.products.phylaxis_ztna'),
            description: t('navigation.products.ztna_description'),
            stat: 'beta',
            href: '#',
            icon: FingerPrintIcon,
        },
    ];

    return (
        <header className='relative isolate z-10 bg-white'>
            <nav className='mx-auto flex max-w-7xl items-center justify-between p-5 lg:px-8' aria-label='Global'>
                <div className='flex lg:flex-1'>
                    <a href='/' className='-m-1.5 p-1.5'>
                        <span className='sr-only'>Phylaxis</span>
                        <img className='h-6 w-auto' src='/img/LogoBlack.svg' alt='Phylaxis Logo' />
                    </a>
                </div>
                <div className='flex lg:hidden'>
                    <button
                        type='button'
                        className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
                        onClick={() => setMobileMenuOpen(true)}>
                        <span className='sr-only'>{t('navigation.open_menu')}</span>
                        <Bars3Icon className='h-6 w-6' aria-hidden='true' />
                    </button>
                </div>
                <Popover.Group className='hidden lg:flex lg:gap-x-12'>
                    <Popover>
                        <motion.div initial='hidden' animate='visible' variants={varMotion(0)}>
                            <Popover.Button className='flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900'>
                                {t('navigation.services')}
                                <ChevronDownIcon className='h-5 w-5 flex-none text-gray-400' aria-hidden='true' />
                            </Popover.Button>
                        </motion.div>

                        <Transition
                            as={Fragment}
                            enter='transition ease-out duration-200'
                            enterFrom='opacity-0 -translate-y-1'
                            enterTo='opacity-100 translate-y-0'
                            leave='transition ease-in duration-150'
                            leaveFrom='opacity-100 translate-y-0'
                            leaveTo='opacity-0 -translate-y-1'>
                            <Popover.Panel className='absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-gray-900/5'>
                                <div className='mx-auto grid max-w-7xl grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8'>
                                    {products.map((item) => (
                                        <div
                                            key={item.name}
                                            className='group relative rounded-lg p-6 text-sm leading-6 hover:bg-gray-50'>
                                            <div className='flex h-11 w-11 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white'>
                                                <item.icon
                                                    className='h-6 w-6 text-gray-600 group-hover:text-phl-secondary-1'
                                                    aria-hidden='true'
                                                />
                                            </div>
                                            <a href={item.href} className='mt-6 block font-semibold text-gray-900'>
                                                {item.name}
                                                <sup>
                                                    <i>{item.stat === 'beta' ? 'Closed-Beta' : ''}</i>
                                                </sup>
                                                <span className='absolute inset-0' />
                                            </a>
                                            <p className='mt-1 text-gray-600'>{item.description}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className='bg-gray-50'>
                                    <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                                        <div className='grid grid-cols-3 divide-x divide-gray-900/5 border-x border-gray-900/5'>
                                            {callsToAction.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className='flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100'>
                                                    <item.icon
                                                        className='h-5 w-5 flex-none text-gray-400'
                                                        aria-hidden='true'
                                                    />
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>
                    <motion.a initial='hidden' animate='visible' variants={varMotion(0.15)}>
                        <a href='/waap/pricing' className='text-sm font-semibold leading-6 text-gray-900'>
                            {t('navigation.pricing')}
                        </a>
                    </motion.a>
                    <motion.a initial='hidden' animate='visible' variants={varMotion(0.3)}>
                        <a href='https://blog.phylaxis.net/' className='text-sm font-semibold leading-6 text-gray-900'>
                            {t('navigation.blog')}
                        </a>
                    </motion.a>
                    <motion.a initial='hidden' animate='visible' variants={varMotion(0.45)}>
                        <a href='/announcement' className='text-sm font-semibold leading-6 text-gray-900'>
                            {t('navigation.announcement')}
                        </a>
                    </motion.a>
                    <motion.a initial='hidden' animate='visible' variants={varMotion(0.6)}>
                        <a href='/ir' className='text-sm font-semibold leading-6 text-gray-900'>
                            {t('navigation.ir')}
                        </a>
                    </motion.a>
                    <motion.a initial='hidden' animate='visible' variants={varMotion(0.75)}>
                        <a href='/about' className='text-sm font-semibold leading-6 text-gray-900'>
                            {t('navigation.about')}
                        </a>
                    </motion.a>
                </Popover.Group>
                {/* 언어 선택 kr, en dropdown */}
                <div className='hidden lg:flex lg:flex-1 lg:justify-end'>
                    <a
                        href='https://portal.phylaxis.net/'
                        className='text-sm font-semibold leading-6 text-gray-900 relative'>
                        Portal <span aria-hidden='true'>&rarr;</span>
                    </a>
                </div>
                <Menu as='div' className='relative inline-block text-left'>
                    <div>
                        <MenuButton className='ml-5 inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900'>
                            {t('navigation.language')}
                            <ChevronDownIcon className='-mr-1 h-5 w-5 text-gray-400' aria-hidden='true' />
                        </MenuButton>
                    </div>

                    <Transition
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <MenuItems className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <div className='py-1'>
                                <MenuItem>
                                    {({ focus }) => (
                                        <a
                                            href='#'
                                            onClick={() => changeLanguage('ko')}
                                            className={classNames(
                                                focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            )}>
                                            한국어
                                        </a>
                                    )}
                                </MenuItem>
                                <MenuItem>
                                    {({ focus }) => (
                                        <a
                                            href='#'
                                            onClick={() => changeLanguage('en')}
                                            className={classNames(
                                                focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            )}>
                                            English
                                        </a>
                                    )}
                                </MenuItem>
                            </div>
                        </MenuItems>
                    </Transition>
                </Menu>
            </nav>
            <Dialog as='div' className='lg:hidden' open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className='fixed inset-0 z-10' />
                <Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
                    <div className='flex items-center justify-between'>
                        <a href='/' className='-m-1.5 p-1.5'>
                            <span className='sr-only'>Phylaxis</span>
                            <img className='h-6 w-auto' src='/img/LogoBlack.svg' alt='' />
                        </a>
                        <button
                            type='button'
                            className='-m-2.5 rounded-md p-2.5 text-gray-700'
                            onClick={() => setMobileMenuOpen(false)}>
                            <span className='sr-only'>{t('navigation.close_menu')}</span>
                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                        </button>
                    </div>
                    <div className='mt-6 flow-root'>
                        <div className='-my-6 divide-y divide-gray-500/10'>
                            <div className='space-y-2 py-6'>
                                <Disclosure as='div' className='-mx-3'>
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className='flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                                {t('navigation.services')}
                                                <ChevronDownIcon
                                                    className={classNames(
                                                        open ? 'rotate-180' : '',
                                                        'h-5 w-5 flex-none'
                                                    )}
                                                    aria-hidden='true'
                                                />
                                            </Disclosure.Button>
                                            <Disclosure.Panel className='mt-2 space-y-2'>
                                                {[...products, ...callsToAction].map((item) => (
                                                    <Disclosure.Button
                                                        key={item.name}
                                                        as='a'
                                                        href={item.href}
                                                        className='block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                                        {item.name}
                                                        <sup>
                                                            <i>{item.stat === 'beta' ? 'Closed-Beta' : ''}</i>
                                                        </sup>
                                                    </Disclosure.Button>
                                                ))}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                <a
                                    href='/waap/pricing'
                                    className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                    {t('navigation.pricing')}
                                </a>
                                <a
                                    href='https://blog.phylaxis.net/'
                                    className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                    {t('navigation.blog')}
                                </a>
                                <a
                                    href='/announcement'
                                    className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                    {t('navigation.announcement')}
                                </a>
                                <a
                                    href='/ir'
                                    className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                    {t('navigation.ir')}
                                </a>
                                <a
                                    href='/about'
                                    className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                    {t('navigation.about')}
                                </a>
                            </div>
                            <div className='py-6'>
                                <a
                                    href='#'
                                    className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                    Portal
                                </a>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
}
