const stats = [
    { label: '악성 요청 표본 수', value: '1,300억건 +' },
    { label: '악성 요청 차단 규칙', value: '360패턴 +' },
]

export default function Mission() {
    return (
        <div className="bg-white py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">우리의 임무</h2>
                    <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
                        <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                            <p className="text-xl leading-8 text-gray-600">

                            </p>
                            <p className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                                Phylaxis는 2023년부터 "안전한 인터넷 세상을 위해"라는 사명감을 가진 구성원이 모여 시작된 회사입니다.
                                Phylaxis는 CDN, Cloud WAF, DDoS Protection, Bot Mitigation 등의 솔루션을 제공하며,
                                고객의 성공을 위해 최선을 다하는 것을 항상 약속드립니다.

                                Phylaxis는 귀사가 언제나 믿고 기댈 수 있는 파트너가 되겠습니다.
                            </p>
                        </div>
                        <div className="lg:flex lg:flex-auto lg:justify-center">
                            <dl className="w-64 space-y-8 xl:w-80">
                                {stats.map((stat) => (
                                    <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                                        <dt className="text-base leading-7 text-gray-600">{stat.label}</dt>
                                        <dd className="text-5xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
