import { motion, Variants, AnimatePresence } from 'framer-motion';
import { useId, useEffect, useState } from 'react';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

const variants: Variants = {
    offscreen: {
        y: 50,
        opacity: 0,
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: 'spring',
            bounce: 0.1,
            duration: 0.8,
        },
    },
};

const fadeVariants = {
    initial: {
        opacity: 0,
        scale: 0.95,
    },
    animate: {
        opacity: 1,
        scale: 1,
        transition: {
            opacity: { duration: 0.5 },
            scale: { duration: 0.5 },
        },
    },
    exit: {
        opacity: 0,
        scale: 0.95,
        transition: {
            opacity: { duration: 0.5 },
            scale: { duration: 0.5 },
        },
    },
};

const perWordVar = (delay: number) => {
    return {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: delay,
                type: 'spring',
                stiffness: 100,
            },
        },
    };
};

const AnimatedText = ({ text, delayPerWord = 0.05 }: { text: string; delayPerWord?: number }) => {
    const segments = text.split(/( )|(<br\/>)/g).filter(Boolean);
    return (
        <div>
            {segments.map((word: any, index: any) =>
                word === '<br/>' ? (
                    <div key={`break-${index}`} style={{ width: '100%' }}></div>
                ) : (
                    <motion.h2
                        style={{ display: 'inline-block', marginRight: '0.3rem' }}
                        variants={perWordVar(index * delayPerWord)}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{ once: true, amount: 0 }}>
                        {/* 위에서 아래로 밝아지는(투명도 0에서 1로) 효과 */}
                        <h2 className='text-xl font-bold tracking-tight md:text-3xl text-white opacity-80 hover:opacity-100 transition duration-300 hover:scale-105 hover:shadow-white hover:drop-shadow-[10_10px_10px_white]'>
                            {word}
                        </h2>
                    </motion.h2>
                )
            )}
        </div>
    );
};

const AnimatedTextPTag = ({ text, delayPerWord = 0.1 }: { text: string; delayPerWord?: number }) => {
    const words = text.split(' ');
    return (
        <div>
            {words.map((word: any, index: any) => (
                <motion.p
                    style={{ display: 'inline-block', marginRight: '0.3rem' }}
                    variants={perWordVar(index * delayPerWord)}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{ once: true, amount: 0 }}>
                    <p className='mt-4 text-lg leading-8 text-gray-400'>{word}</p>
                </motion.p>
            ))}
        </div>
    );
};

export default function Example() {
    const [currentIndex, setCurrentIndex] = useState(0);
    // Add a state to track manual changes
    const [manualChange, setManualChange] = useState(false);

    const { t } = useTranslation();

    const partners = [
        {
            id: 1,
            name: t('index.packet_stream'),
            type: 'B2B',
            logoPath: '/img/partner/ps_white.svg',
            msg: t('index.packet_stream_msg'),
        },
        {
            id: 2,
            name: t('index.01republic'),
            type: 'B2B',
            logoPath: '/img/partner/01rep_white.png',
            msg: t('index.01republic_msg'),
        },
        {
            id: 3,
            name: t('index.stella_it'),
            type: 'B2C/B',
            logoPath: '/img/partner/stla_white.svg',
            msg: t('index.stella_it_msg'),
        },
        {
            id: 4,
            name: t('index.craft_studio'),
            type: 'B2G/B',
            logoPath: '/img/partner/cstudio_white.png',
            msg: t('index.craft_studio_msg'),
        },
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!manualChange) {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % partners.length);
            }
            // Reset manual change state after handling it
            setManualChange(false);
        }, 5000);

        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
    }, [partners.length, manualChange]); // Add manualChange as a dependency

    const handleNextPartner = () => {
        setCurrentIndex((currentIndex + 1) % partners.length);
        setManualChange(true); // Indicate that a manual change occurred
    };

    const handlePrevPartner = () => {
        setCurrentIndex((currentIndex - 1 + partners.length) % partners.length);
        setManualChange(true); // Indicate that a manual change occurred
    };
    return (
        <>
            <section className='relative isolate overflow-hidden bg-phl-primary px-6 py-24 sm:py-32 lg:px-8'>
                <div className='absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.blue.900),white)] opacity-10' />
                <div className='absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-phl-primary shadow-xl shadow-indigo-600/10 ring-1 ring-phl-primary sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center' />
                <div className='mx-auto max-w-2xl lg:max-w-4xl grid-cols-8 gap-4 grid'>
                    <div className='col-span-1 text-left items-center justify-end w-full h-full vertical-center flex m-auto'>
                        <button
                            onClick={handlePrevPartner}
                            className='items-center justify-center w-12 h-12 text-white bg-black bg-opacity-40 rounded-full shadow-lg'>
                            <ChevronLeftIcon className='w-6 h-6 m-auto' />
                        </button>
                    </div>
                    <AnimatePresence mode='wait'>
                        <motion.div
                            key={partners[currentIndex].id}
                            variants={fadeVariants}
                            initial='initial'
                            animate='animate'
                            exit='exit'
                            className='relative flex flex-col items-center justify-center w-full h-96 col-span-6 py-20 bg-black/10 rounded-2xl shadow-lg'>
                            <div className='relative flex flex-col items-center justify-center w-full h-96 col-span-6 py-20 bg-black/10 rounded-2xl shadow-lg'>
                                <img
                                    className='mx-auto w-[120px] pt-5'
                                    src={partners[currentIndex].logoPath}
                                    alt={partners[currentIndex].name}
                                />
                                <figure className='mt-10'>
                                    <blockquote className='text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9'>
                                        <div>
                                            <AnimatedText text={partners[currentIndex].msg} />
                                        </div>
                                    </blockquote>
                                    <figcaption className='mt-10 pb-5'>
                                        <div className='mt-4 flex items-center justify-center space-x-3 text-base'>
                                            <motion.div initial='offscreen' whileInView='onscreen' variants={variants}>
                                                <div className='text-gray-200'>
                                                    {partners[currentIndex].name} | {partners[currentIndex].type}
                                                </div>
                                            </motion.div>
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                    <div className='col-span-1 text-left items-center justify-start w-full h-full vertical-center flex m-auto'>
                        <button
                            onClick={handleNextPartner}
                            className='items-center justify-center w-12 h-12 text-white bg-black bg-opacity-40 rounded-full shadow-lg'>
                            <ChevronRightIcon className='w-6 h-6 m-auto' />
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
}
