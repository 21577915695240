import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import IndexPage from '../Pages/index';
import NotFoundPage from '../Pages/404';
import IntroPage from '../Pages/introduce';
import AddosIntro from '../Pages/addosIntro';
import IrBoard from '../Pages/irBoard';
import IrDetail3 from '../Pages/irDetail3';
import IrDetail4 from '../Pages/irDetail4';
import AnnouncementDetail from '../Pages/announcementDetail';
import AnnouncementDetail2 from '../Pages/announcementDetail2';
import AnnouncementDetail3 from '../Pages/announcementDetail3';
import AnnouncementBoard from '../Pages/announcementBoard';
import RequestEnterprise from '../Pages/requestEnterprise';
import PricingPage from '../Pages/proxyPricing';
import RequestPartnership from '../Pages/requestPartnership';
import Privacy from '../Pages/privacy';
import Sla from '../Pages/sla';
import Terms from '../Pages/terms';
import Calculator from '../Pages/calculator';
import PartnerRedir from '../Pages/partnerRedir';
import { Brochure } from '../Pages/brochure';
import { Navigate } from 'react-router-dom';
import EventTracker from '../EventTracker';
import Marquee from '../Components/Banner';
import { motion, Variants } from 'framer-motion';

const variants: Variants = {
    offscreen: {
        opacity: 0,
        y: -50,
    },
    onscreen: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'spring',
            bounce: 0,
            duration: 2,
        },
    },
};

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <EventTracker />
            <motion.div
                initial='offscreen'
                animate='onscreen'
                variants={variants}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 9999,
                }}>
                <Marquee />
            </motion.div>
            <div style={{ height: '35px' }}></div>
            <Routes>
                <Route path='/' element={<IndexPage />} />
                <Route path='/about' element={<IntroPage />} />
                <Route path='/waap/about' element={<AddosIntro />} />
                <Route path='/waap/pricing' element={<PricingPage />} />
                <Route path='/ir' element={<IrBoard />} />
                <Route path='/ir/board/3' element={<IrDetail3 />} />
                <Route path='/ir/board/4' element={<IrDetail4 />} />
                <Route path='/announcement' element={<AnnouncementBoard />} />
                <Route path='/announcement/board/1' element={<AnnouncementDetail />} />
                <Route path='/announcement/board/2' element={<AnnouncementDetail2 />} />
                <Route path='/announcement/board/3' element={<AnnouncementDetail3 />} />
                <Route path='/partnership/request' element={<RequestPartnership />} />
                <Route path='/inquiry' element={<RequestEnterprise />} />
                <Route path='/partner_redir' element={<PartnerRedir />} />
                <Route path='/inquiry/brochure' element={<Brochure />} />
                <Route path='/sla' element={<Sla />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/calculator' element={<Calculator />} />
                <Route path='/404' element={<NotFoundPage />} />
                <Route path='*' element={<Navigate to='/404' />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
