import React, { useState, useRef } from 'react';
import Navigation from '../Components/Navigation/Navigation';
import Content from '../Components/IntroContents/Content';
import { useStrictMediaQuery } from '../Utils/hooks';
import Hero from '../Components/PartnerRedir/Hero';
import Footer from '../Components/Footer';

const PartnerRedir = () => {
    return (
        <>
            <a id='top' />
            <Navigation />
            <Hero />
            <Footer />
        </>
    );
};

export default PartnerRedir;
