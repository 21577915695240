import { ServerStackIcon, UserIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Partners() {
  const { t } = useTranslation();

  const partners = [
    {
      name: "제로원리퍼블릭",
      src: "/img/partner/01rep.png",
      width: 100,
      height: 14,
    },
    {
      name: "Craft Studio",
      src: "/img/partner/cstudio.webp",
      width: 120,
      height: 28,
    },
    {
      name: "scordi",
      src: "/img/partner/scordi.svg",
      width: 100,
      height: 28,
    },
    {
      name: "stella-it",
      src: "/img/partner/stellait.svg",
      width: 80,
      height: 34,
    },
    {
      name: "swyg",
      src: "/img/partner/swyglogo.svg",
      width: 90,
      height: 20,
    },
    {
      name: "Fall In Vibe",
      src: "/img/partner/favi.webp",
      width: 50,
      height: 24,
    },
    {
      name: "Eyejoker Db",
      src: "/img/partner/eyedb.svg",
      width: 100,
      height: 16,
    },
    {
      name: "PacketStream",
      src: "/img/partner/packetstream.svg",
      width: 128,
      height: 18,
    },
    {
      name: "Solved AC",
      src: "/img/partner/solvedac.png",
      width: 102,
      height: 16,
    },
    {
      name: "PANTHERA",
      src: "/img/partner/panthera.svg",
      width: 110,
      height: 14,
    },
  ];

  return (
    <div className="py-16 bg-gradient-to-b from-[#F5F5F5] to-gray-100">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-3xl font-bold mb-4">
            {t("index.lot_of_partner")}
          </h1>
          <p className="text-baseline font-light text-gray-600 max-w-3xl mx-auto">
            {t("index.lot_of_partner_sub")}
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8"
        >
          {partners.map((partner, index) => (
            <motion.div
              key={partner.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="flex items-center justify-center"
            >
              <img
                src={partner.src}
                alt={partner.name}
                width={partner.width}
                height={partner.height}
                className="max-w-full h-auto"
              />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}
