import React from 'react'
import { useLocation } from 'react-router-dom';

const partnerTypes = [
    {
        type: 'vps',
        typeName: "VPS 호스팅",
        partnerName: "Packetstream",
        partnerUrl: "https://packet.stream/ko/plans/cloud-server",
        description: "패킷스트림은 고가용성의 클라우드 컴퓨팅 서비스를 합리적인 가격에 제공하고 있습니다.",
        logo: "/img/partner/ps_white.svg",
    },{
        type: 'server',
        typeName: "서버 호스팅",
        partnerName: "Stella-IT",
        partnerUrl: "https://stella-it.cloud/plans/dedicated",
        description: "스텔라아이티 주식회사는 고가용성의 호스팅 서비스를 합리적인 가격에 제공하고 있습니다.",
        logo: "/img/partner/stla_white.svg",
    },{
        type: 'colocation',
        typeName: "코로케이션",
        partnerName: "DIGITALASSET APAC",
        partnerUrl: "https://daapac.netlify.app/",
        description: "디지털에셋아시아퍼시픽은 안전하고 유연한 데이터센터를 합리적인 가격에 제공하고 있습니다.",
    },{
        type: 'line',
        typeName: "회선",
        partnerName: "DIGITALASSET APAC",
        partnerUrl: "https://daapac.netlify.app/",
        description: "디지털에셋아시아퍼시픽은 회선을 합리적인 가격에 제공하고 있습니다.",
    }
]

export default function Example() {
    const queryType = new URLSearchParams(useLocation().search).get('type');
    if (!partnerTypes.find((type) => type.type === queryType)) {
        window.location.href = "/404";
    }
    return (
            <div className="relative isolate overflow-hidden bg-gray-900 pt-20">
                <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            {partnerTypes.find((type) => type.type === queryType)?.typeName}은
                            <br />
                            파트너사 {partnerTypes.find((type) => type.type === queryType)?.partnerName}에서 제공하고 있습니다.
                        </h2>
                        <p className="mt-6 text-base leading-7 text-gray-300">
                            {partnerTypes.find((type) => type.type === queryType)?.description}
                        </p>
                        {partnerTypes.find((type) => type.type === queryType)?.logo ? (
                            <img src={partnerTypes.find((type) => type.type === queryType)?.logo} className="mx-auto mt-8" width="150px" />
                        ) : (
                            <></>
                        )}
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href={partnerTypes.find((type) => type.type === queryType)?.partnerUrl}
                                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >
                                이동하기
                            </a>
                            <a href="/" className="text-sm font-semibold leading-6 text-white">
                                돌아가기 <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </div>
                <svg
                    viewBox="0 0 1024 1024"
                    className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                    aria-hidden="true"
                >
                    <circle cx={512} cy={512} r={512} fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fillOpacity="0.7" />
                    <defs>
                        <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
                            <stop stopColor="#7775D6" />
                            <stop offset={1} stopColor="#E935C1" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>
    )
}