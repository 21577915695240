import React, { useState, useRef } from "react";
import Navigation from "../Components/Navigation/Navigation";
import NotFoundContent from "../Components/404Content";
import { useStrictMediaQuery } from "../Utils/hooks";
import Footer from "../Components/Footer";
import Pricing from "../Components/PricingContents/Pricing";

const PricingPage = () => {
  return (
    <>
      <a id="top" />
      <Navigation />
      <Pricing />
      <Footer />
    </>
  );
};

export default PricingPage;
