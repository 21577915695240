import React, { useState, useRef } from "react";
import Navigation from "../Components/Navigation/Navigation";
import Content from "../Components/IndexContents/Content";
import Content2 from "../Components/IndexContents/Content2";
import { useStrictMediaQuery } from "../Utils/hooks";
import Content3 from "../Components/IndexContents/Content3";
import Content4 from "../Components/IndexContents/Content4";
import Content5 from "../Components/IndexContents/Content5";
import Testimonals from "../Components/IndexContents/Testimonals";
import Reasonable from "../Components/IndexContents/Reasonable";
import RoundedBox from "../Components/IndexContents/RoundedBox";
import Footer from "../Components/Footer";
import Helmet from "react-helmet";
import DDoSStats from "../Components/IntroContents/DDoSStats";
import LiveStat from "../Components/IndexContents/LiveStat";
import Partners from "../Components/IndexContents/Partners";
import CTI from "../Components/IndexContents/cti";

const IndexPage = () => {
  return (
    <>
      <a id="top" />
      <Navigation />
      <Content />
      <Partners />
      <LiveStat />
      <Content2 />
      <Reasonable />
      <Testimonals />
      <Content3 />
      <CTI />
      <Content4 />
      <Content5 />
      <Footer />
    </>
  );
};

export default IndexPage;
