import { motion, Variants } from "framer-motion";
import { useId } from "react";
import { useTranslation } from "react-i18next";

const stats = [
  { id: 1, name: "도메인 연결됨", value: "80+" },
  { id: 2, name: "지금까지 완화한 악성 요청", value: "430억 +" },
  { id: 3, name: "60일 업타임", value: "99.999%" },
  { id: 4, name: "1일 평균 요청 처리수", value: "3000만 +" },
];

const variants: Variants = {
  offscreen: {
    y: 100,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.1,
      duration: 0.8,
    },
  },
};

const perWordVar = (delay: number) => {
  return {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: delay,
        type: "spring",
        stiffness: 100,
      },
    },
  };
};

const AnimatedText = ({
  text,
  delayPerWord = 0.1,
}: {
  text: string;
  delayPerWord?: number;
}) => {
  const words = text.split(" ");
  return (
    <div>
      {words.map((word: any, index: any) => (
        <motion.h2
          style={{ display: "inline-block", marginRight: "0.5rem" }}
          variants={perWordVar(index * delayPerWord)}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0 }}
        >
          <h2 className="text-4xl font-bold tracking-tight text-gray-900">
            {word}
          </h2>
        </motion.h2>
      ))}
    </div>
  );
};

export default function Example() {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none inline justify-center items-center sm:flex">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={variants}
          >
            <img
              src={t("index.security_no_pressure_img")}
              className="w-[400px] sm:w-[500px] lg:w-[600px] rounded-xl"
            />
          </motion.div>
          <div className="text-left ml-0 sm:ml-10 mt-10 sm:mt-0">
            <AnimatedText text={t("index.security_no_pressure")} />
            <motion.p
              style={{ display: "inline-block", marginRight: "0.3rem" }}
              variants={perWordVar(0)}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0 }}
            >
              <p className="mt-4 text-lg leading-8 text-gray-400">
                {t("index.security_no_pressure_sub")}
              </p>
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
}
