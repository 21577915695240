import { useId } from 'react';
import { motion } from 'framer-motion';

const perWordVar = (delay: number) => {
    return {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: delay,
                type: 'spring',
                stiffness: 100,
            },
        },
    };
};

const showFromLeft = (delay: number) => {
    return {
        hidden: { opacity: 0, x: -30 },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                delay: delay,
                type: 'spring',
                stiffness: 100,
            },
        },
    };
};

const AnimatedText = ({ text, delayPerWord = 0.1 }: { text: string; delayPerWord?: number }) => {
    const words = text.split(' ');
    return (
        <div>
            {words.map((word: any, index: any) => (
                <motion.h2
                    style={{ display: 'inline-block', marginRight: '0.5rem' }}
                    variants={perWordVar(index * delayPerWord)}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{ once: true, amount: 0 }}>
                    <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>{word}</h2>
                </motion.h2>
            ))}
        </div>
    );
};

const AnimatedTextPTag = ({ text, delayPerWord = 0.05 }: { text: string; delayPerWord?: number }) => {
    const words = text.split(' ');
    return (
        <div>
            {words.map((word: any, index: any) => (
                <motion.p
                    style={{ display: 'inline-block', marginRight: '0.3rem' }}
                    variants={perWordVar(index * delayPerWord)}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{ once: true, amount: 0 }}>
                    <p className='mt-4 text-lg leading-8 text-gray-400'>{word}</p>
                </motion.p>
            ))}
        </div>
    );
};

export default function DDoSStats() {
    return (
        <div className='bg-gray-100 py-24 sm:py-24'>
            <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                <div className='mx-auto max-w-2xl lg:mx-0'>
                    <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                        <AnimatedText text='인터넷은 위험합니다. 당신의 상상보다 더.' />
                    </h2>
                    <p className='mt-6 text-base leading-7 text-gray-600'>
                        <AnimatedTextPTag text='그래서 우리가 모였습니다, Team Phylaxis는 인터넷을 더 안전하게 만들기 위해 노력합니다.' />
                    </p>
                </div>
                <div className='mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end'>
                    <motion.div
                        initial='hidden'
                        variants={showFromLeft(0)}
                        whileInView='visible'
                        viewport={{ once: false, amount: 0 }}>
                        <div className='flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-500 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start'>
                            <p className='flex-none text-3xl font-bold tracking-tight text-white'>+ 65%</p>
                            <div className='sm:w-80 sm:shrink lg:w-auto lg:flex-none'>
                                <p className='text-lg font-semibold tracking-tight text-white'>전 분기 대비 L7 공격</p>
                                <p className='mt-2 text-base leading-7 text-gray-300'>
                                    KISA 발표에 따르면 2023년 3분기에는 전 분기 대비 L7 공격이 65% 증가했습니다.
                                </p>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        initial='hidden'
                        variants={showFromLeft(0.5)}
                        whileInView='visible'
                        viewport={{ once: false, amount: 0 }}>
                        <div className='flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44'>
                            <p className='flex-none text-3xl font-bold tracking-tight text-white'>90% +</p>
                            <div className='sm:w-80 sm:shrink lg:w-auto lg:flex-none'>
                                <p className='text-lg font-semibold tracking-tight text-white'>
                                    Phylaxis WAAP L7 공격 차단율
                                </p>
                                <p className='mt-2 text-base leading-7 text-gray-400'>
                                    어떠한 상황속에서도 견고하게, 우리는 공격을 차단합니다.
                                </p>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        initial='hidden'
                        variants={showFromLeft(1)}
                        whileInView='visible'
                        viewport={{ once: false, amount: 0 }}>
                        <div className='flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-phl-secondary-1 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28'>
                            <p className='flex-none text-3xl font-bold tracking-tight text-white'>6위</p>
                            <div className='sm:w-80 sm:shrink lg:w-auto lg:flex-none'>
                                <p className='text-lg font-semibold tracking-tight text-white'>L7 공격 대상 국가</p>
                                <p className='mt-2 text-base leading-7 text-indigo-200'>
                                    대한민국은 L7 공격 대상 국가 6위에 올랐습니다.
                                    <br />
                                    어떠한 서비스라도 예외일 수 없습니다.
                                </p>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}
