import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";

export default function Example() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 text-end">
        <div className="lg:col-span-2 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-phl-secondary-1">
                번거로운 일 제거
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                SSL도 척척
              </h1>
            </div>
          </div>
        </div>
        <div className="-mr-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-1 lg:row-span-2 lg:row-start-1 lg:overflow-hidden items-center justify-end lg:flex">
          <img
            className="w-[48rem] max-w-none rounded-xl sm:w-[57rem]"
            src="/img/portal_ssl.png"
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-2 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg text-start">
              <p className="text-end">
                Phylaxis 관리형 인증서를 통해 자동으로 SSL 인증서를 발급받고,
                갱신할 수 있습니다. 더 이상 번거롭게 인증서를 발급받을 필요가
                없습니다.
              </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon
                    className="mt-1 h-5 w-5 flex-none text-phl-secondary-1"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Zone 생성 시 자동 등록.
                    </strong>{" "}
                    새로운 Zone을 생성할 때, 자동으로 SSL 인증서를 등록합니다.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LockClosedIcon
                    className="mt-1 h-5 w-5 flex-none text-phl-secondary-1"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      더 안전하게.
                    </strong>{" "}
                    Root CA 인증서를 통해, 더욱 많은 브라우저에서 신뢰할 수 있는
                    인증서를 제공합니다.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon
                    className="mt-1 h-5 w-5 flex-none text-phl-secondary-1"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      인증서는 철저히.
                    </strong>{" "}
                    Phylaxis는 인증서를 안전한 저장소에 보관하며, 누출로 인한
                    피해를 방지합니다.
                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-2xl font-light tracking-tight text-gray-900">
                <p className="flex items-center gap-x-2">
                  <img src="/img/gts-logo.svg" className="h-6" /> 에서 발급한
                  인증서
                </p>
              </h2>
              <p className="mt-6">
                신뢰할 수 있는 기관인 구글에서 서명한 인증서로,
                <br /> 더욱 안전하게 서비스를 제공합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
