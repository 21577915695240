import React from "react";
import "tailwindcss/tailwind.css";
import { Text } from "../Texts";
import { ButtonGroup, ButtonSecondary, ButtonWhitePrimary } from "../Buttons";
import { Union, tw, handleOpenExternal } from "../../Utils";
import { IChildrenProps } from "../../Interfaces";
import { Container } from "../Container";

const contentOuter = tw.style({
    backgroundColor: "bg-white",
    width: "w-full",
    overflow: "overflow-hidden",
    padding: "p-6",
    paddingTop: "pt-[90px]",
});

const ContentOuter = ({ children }: IChildrenProps) => {
    return <div className={contentOuter.class}>{children}</div>;
};

const emblemContainer = tw.style({
    display: "flex",
    flexDirection: "flex-col",
    marginLeft: "ml-auto",
    justifyContent: "justify-center",
    marginRight: "mr-8",
    "@max-md": {
        display: "max-md:hidden",
    },
});

const emblem = tw.style({
    "@max-lg": {
        width: "max-lg:w-[700px]",
    },
    "@lg": {
        width: "lg:w-[600px]",
    },
});

const flexContainer = tw.style({
    display: "flex",
    flexDirection: "flex-row",
    textAlign: "text-left",
    marginTop: "mt-12",
});


const flexContainer2 = tw.style({
    display: "flex",
    flexDirection: "flex-row",
    textAlign: "text-left",
});

const textBox = tw.style({
    display: "flex",
    flexDirection: "flex-col",
    justifyContent: "justify-center",
    width: "w-full",
    paddingRight: "pr-8",
    paddingLeft: "pl-8",
    "@max-sm": {
        textAlign: "max-sm:text-center",
    },
});

const AnnouncementDetail = () => {
    return (
        <div className={contentOuter.class}>
            <Container>
                <div className={flexContainer.class}>
                    <div className={textBox.class}>
                        <Text size="lg" style={tw.style({ "@max-sm": { fontSize: "max-sm:text-2xl" } })}>
                            Phylaxis WAAP) 출시일 연기에 관한 건
                        </Text>
                        <Text size="md" className="font-semibold">
                            2023년 12월 20일
                        </Text>
                    </div>
                </div>
                <div className="border-b border-slate-700 border-opacity-50"></div>
            </Container>
            <Container>
                <div className={flexContainer2.class}>
                    <div className={textBox.class}>
                        당초 2023년 12월 22일 출시 예정이었던 Phylaxis WAAP의 출시일을 2023년 12월 28일로 연기하게 되었습니다.
                        <br />
                        <br />
                        출시 연기의 이유는 아래와 같습니다:
                        <br />
                        <br />
                        <ul className="list-disc list-inside">
                            <li>SEL-01 리전의 네트워크 구성이 완전히 안정화되지 않았습니다. 이에 따라, 보완 작업이 필요합니다.</li>
                            <li>인증서 DNS의 공격 차단 규칙이 불안정하여 보완 작업을 진행중입니다.</li>
                        </ul>
                        <br />
                        <br />
                        위 사유로 인해, Phylaxis WAAP의 출시일을 2023년 12월 28일로 연기하게 되었습니다.
                        <br />
                        안정적인 서비스 제공을 위한 연기 결정에 대해, 이해해주시기 바랍니다.
                        <br />
                        <br />
                        감사합니다.
                        <br />
                        <br />
                        <div className="flex flex-row font-semibold text-lg text-gray-600">
                            <img src="/img/LogoAllGray.svg" alt="logo" className="w-24 mr-2" />팀 드림
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default AnnouncementDetail;