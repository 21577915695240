import React, { useState, useRef } from 'react';
import Navigation from '../Components/Navigation/Navigation';
import Content from '../Components/IntroContents/Content';
import { useStrictMediaQuery } from '../Utils/hooks';
import Footer from '../Components/Footer';
import ProxyJumbo from '../Components/ProxyContents/Jumbo';
import ProxyFeatures from '../Components/ProxyContents/Features';
import ProxySSL from '../Components/ProxyContents/FeatureSSL';
import LogoCloudWhite from '../Components/PartnershipContents/LogoCloudColor';
import { Helmet } from 'react-helmet';
import LiveStat from '../Components/IndexContents/LiveStat';

const AddosIntro = () => {
    return (
        <>
            <Helmet>
                <title>Phylaxis: Phylaxis WAAP 제품소개</title>
                <meta name='og:title' content='Phylaxis: Phylaxis WAAP 제품소개' />
                <meta
                    name='og:description'
                    content='DDoS 완화율 90% 이상, 웹 사이트 자동 최적화, 캐싱 서비스, 방화벽 등 Phylaxis WAAP 제품소개'
                />
                <meta
                    name='description'
                    content='DDoS 완화율 90% 이상, 웹 사이트 자동 최적화, 캐싱 서비스, 방화벽 등 Phylaxis WAAP 제품소개'
                />
            </Helmet>
            <a id='top' />
            <Navigation />
            <ProxyJumbo />
            <LiveStat />
            <LogoCloudWhite />

            <ProxyFeatures />
            <hr className='h-px bg-gray-200 border-0 dark:bg-gray-200'></hr>
            <Footer />
        </>
    );
};

export default AddosIntro;
