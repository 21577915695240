import { ServerStackIcon, UserIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function LiveStat() {
  const [latency, setLatency] = useState("0");
  const [opacity, setOpacity] = useState(0);
  const [color, setColor] = useState("text-blue-500");

  const { t } = useTranslation("");

  function toSecond(latency: any) {
    return Number(latency) / 1000;
  }

  function getLatencyColor(latency: any) {
    if (Number(latency) >= 200) {
      return "text-red-500";
    } else if (Number(latency) >= 100) {
      return "text-yellow-500";
    } else {
      return "text-gray-100";
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const startTime = Date.now();
      axios
        .head(`https://dev-api.phylaxis.net/ping`, {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        })
        .then(() => {
          const endTime = Date.now();
          const calculatedRtt = endTime - startTime;
          setLatency(String(calculatedRtt - 1));
          setOpacity(1);
        })
        .catch((error) => {
          setLatency("ERR");
          setOpacity(0);
          console.error("Error measuring RTT:", error);
        });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setOpacity(0);
    setTimeout(() => setOpacity(1), 200);
  }, [latency]);

  useEffect(() => {
    setColor(getLatencyColor(latency));
  }, [latency]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      className="bg-black/90 py-20 sm:py-24"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <style>{`
                @keyframes moveAcross {
                    0% {
                        left: 0;
                        width: 0;
                    }
                    50% {
                        left: 0;
                        width: 100%;
                    }
                    50.01% {
                        left: 0;
                        width: 100%;
                    }
                    100% {
                        left: 100%;
                        width: 0;
                    }
                }
                .loading-bar {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    background-color: #fff;
                    animation: moveAcross 3s linear infinite;
                }
            `}</style>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <motion.div variants={itemVariants} className="text-center mb-12">
          <h2 className="text-3xl font-light text-white">
            <span className="font-semibold text-blue-600">Phylaxis Edge</span>{" "}
            {t("index.and_your_http_rtt")}
          </h2>
          <p className="text-gray-200 mt-2">{t("index.rtt_description")}</p>
        </motion.div>
        <motion.div
          variants={itemVariants}
          className="flex items-center justify-between mt-10"
        >
          <div className="text-center flex flex-col items-center">
            <UserIcon className="w-12 h-12 text-white mb-2" />
            <span className="text-base font-light text-white">
              {t("index.you")}
            </span>
          </div>
          <div className="flex-1 mx-4">
            <div className="h-1 bg-gray-500 relative overflow-hidden">
              <div className="loading-bar"></div>
            </div>
          </div>
          <div className="text-center flex flex-col items-center">
            <motion.span
              animate={{ opacity }}
              transition={{ duration: 0.5 }}
              className={`text-2xl font-light ${color}`}
            >
              {latency}
              {latency === "ERR" ? "" : "ms"}
            </motion.span>
            <span className="text-base font-light text-white mt-1">
              {toSecond(latency)} {t("index.second")}
            </span>
          </div>
          <div className="flex-1 mx-4">
            <div className="h-1 bg-gray-500 relative overflow-hidden">
              <div
                className="loading-bar"
                style={{ animationDelay: "1.5s" }}
              ></div>
            </div>
          </div>
          <div className="text-center flex flex-col items-center">
            <ServerStackIcon className="w-12 h-12 text-white mb-2" />
            <span className="text-base font-light text-white">
              Phylaxis Edge
            </span>
          </div>
        </motion.div>
        <motion.div variants={itemVariants} className="mt-12 text-center">
          <span className="text-lg font-semibold text-gray-400">
            Disclaimer
            <span className="px-1 align-top text-gray-100 text-xs">*</span>
          </span>
          <p className="text-gray-400 mt-1">{t("index.rtt_disclaimer")}</p>
          <p className="text-gray-400">{t("index.rtt_disclaimer_sub")}</p>
        </motion.div>
      </div>
    </motion.div>
  );
}
