import { CircleBackground } from '../CircleBackground';
import { Container } from '../Container2';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export function CallToAction() {
    const containerVariants = {
        hidden: { opacity: 0, scale: 1 },
        visible: { opacity: 1, scale: 1, transition: { delay: 0, duration: 0.8 } },
    };

    const textVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { delay: 0.4, duration: 1 } },
    };

    const perWordVar = (delay: number) => {
        return {
            hidden: { opacity: 0, y: 20 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    delay: delay,
                    type: 'spring',
                    stiffness: 100,
                },
            },
        };
    };

    const AnimatedText = ({ text, delayPerWord = 0.1 }: { text: string; delayPerWord?: number }) => {
        const words = text.split(' ');
        return (
            <div>
                {words.map((word: any, index: any) => (
                    <motion.h2
                        style={{ display: 'inline-block', marginRight: '0.5rem' }}
                        variants={perWordVar(index * delayPerWord)}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{ once: true, amount: 0 }}>
                        <h2 className='text-3xl font-medium tracking-tight text-white sm:text-4xl'>{word}</h2>
                    </motion.h2>
                ))}
            </div>
        );
    };

    const { t } = useTranslation();

    return (
        <motion.section
            id='get-free-shares-today'
            className='relative overflow-hidden bg-[#0f0f0f]'
            variants={containerVariants}
            initial='hidden'
            animate='visible'>
            <section id='get-free-shares-today' className='relative overflow-hidden bg-[#0f0f0f] py-20 sm:py-28'>
                <div className='absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2'>
                    <CircleBackground color='#fff' className='animate-spin-slow' />
                </div>
                <Container className='relative'>
                    <div className='mx-auto max-w-md sm:text-center'>
                        <AnimatedText text={t('index.security_now_subscription')} delayPerWord={0.08} />
                        <motion.p
                            className='mt-4 text-lg text-gray-300'
                            variants={textVariants}
                            initial='hidden'
                            animate='visible'>
                            <p className='mt-4 text-lg text-gray-300'>{t('index.security_now_subscription_sub')}</p>
                        </motion.p>
                    </div>
                </Container>
            </section>
        </motion.section>
    );
}

export default CallToAction;
