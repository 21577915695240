import { Helmet } from "react-helmet"
import ReactPixel from 'react-facebook-pixel';

export default function Hero() {
    const recatch = `<script>
    (function (r, e, c, a, t, ch) {
      var h=r.getElementsByTagName(e)[0],i=r.createElement(c);
      i.async=true;
      i.id='recatch-embed-script';
      i.src='https://cdn.recatch.cc/recatch-embed.iife.js?t='+a[0]+'&b='+a[1]+'&c='+t+'&th='+ch+'&mode=sdk&pc=%234a7cfe';
      h.appendChild(i);
    })(document,'head','script',['phylaxis','gatnqotmlk'],'recatch-form','light');
  </script>`
    return (
        <>
            <Helmet>
                {recatch}
            </Helmet>
            <a id="top">
                <iframe src="https://phylaxis.recatch.cc/workflows/gatnqotmlk?theme=light&primaryColor=%232C46FF" className="w-full h-[1200px]"
                onMouseOver={() => ReactPixel.track('SubmittingInquiry')}></iframe>
            </a>
        </>
    )
}
