import {
    ShieldCheckIcon,
    ServerStackIcon,
    BoltIcon,
    Square2StackIcon,
    CodeBracketSquareIcon,
    FingerPrintIcon,
  } from '@heroicons/react/20/solid'
  
  const features = [
    {
      name: '데이터센터 출입 통제.',
      description: '지문 인식 출입 통제, 24시간 CCTV 관제 및 보안 경비원 상주 등의 철저한 보안 시스템을 갖추고 있습니다.',
      icon: FingerPrintIcon,
    },
    {
      name: '전력 및 네트워크 이중화.',
      description: '전력 및 네트워크 이중화를 통해, 99.9%의 가용성을 보장합니다.',
      icon: Square2StackIcon,
    },
    {
      name: '200+ Tbps DDoS 보안.',
      description: '글로벌 네트워크를 통해, 최대 200+ Tbps의 DDoS 공격을 막아냅니다.',
      icon: ShieldCheckIcon,
    },
    {
      name: '고품질 네트워크.',
      description: '고품질 네트워크를 통해, 빠른 속도와 안정성을 제공합니다.',
      icon: BoltIcon,
    },
    {
      name: '강력한 서버 구성.',
      description: '강력한 서버 구성을 통해, 어떠한 상황에서도 안정적인 서비스를 제공합니다.',
      icon: ServerStackIcon,
    },
    {
      name: '전 과정 이중화.',
      description: '물리적 인프라 뿐 아니라 소프트웨어적인 부분까지 전 과정 이중화를 통해, 안정적인 서비스를 제공합니다.',
      icon: CodeBracketSquareIcon,
    },
  ]
  
  export default function StrongInfra() {
    return (
      <div className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-base font-semibold leading-7 bg-gradient-to-r bg-clip-text text-transparent from-phl-secondary-1 to-phl-secondary-3">강력한 Infrastructure.</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">엔터프라이즈급 서비스를 제공합니다.</p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
                소중한 데이터를 안전하게 보호하고, 고가용성을 유지할 수 있도록 고안되었습니다.
            </p>
          </div>
        </div>
        {/* <div className="relative overflow-hidden pt-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <img
              src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
              alt="App screenshot"
              className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
              width={2432}
              height={1442}
            />
            <div className="relative" aria-hidden="true">
              <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]" />
            </div>
          </div>
        </div> */}
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
          <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-white">
                  <feature.icon className="absolute left-1 top-1 h-5 w-5 text-phl-secondary-2" aria-hidden="true" />
                  {feature.name}
                </dt>{' '}
                <dd className="inline">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    )
  }
  