import { motion, Variants, useInView } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const variants: Variants = {
    offscreen: {
        opacity: 0,
        y: 50,
    },
    onscreen: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'spring',
            bounce: 0,
            duration: 1,
        },
    },
};

const bgVar: Variants = {
    offscreen: {
        opacity: 0,
    },
    onscreen: {
        opacity: 1,
        transition: {
            delay: 0.5,
            duration: 1,
        },
    },
};

const contVar = (delay: number) => {
    return {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: delay,
                duration: 0.8,
            },
        },
    };
};

// key={index}
// initial={{ opacity: 0, y: 20 }}
// animate={{ opacity: 1, y: 0 }}
// transition={{ delay: index * delayPerWord, type: "spring", stiffness: 100 }}
// style={{ display: "inline-block", marginRight: "0.5rem" }}

const perWordVar = (delay: number) => {
    return {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: delay,
                type: 'spring',
                stiffness: 100,
            },
        },
    };
};

const AnimatedText = ({ text, delayPerWord = 0.1 }: { text: string; delayPerWord?: number }) => {
    const words = text.split(' ');
    return (
        <div>
            {words.map((word: any, index: any) => (
                <motion.h2
                    // key={index}
                    // initial={{ opacity: 0, y: 20 }}
                    // animate={{ opacity: 1, y: 0 }}
                    // transition={{ delay: index * delayPerWord, type: "spring", stiffness: 100 }}
                    style={{ display: 'inline-block', marginRight: '0.5rem' }}
                    variants={perWordVar(index * delayPerWord)}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{ once: true, amount: 0 }}>
                    <h2 className='text-3xl font-medium tracking-tight text-white'>{word}</h2>
                </motion.h2>
            ))}
        </div>
    );
};

export default function Example() {
    const { t } = useTranslation();
    return (
        <motion.div initial='offscreen' whileInView='onscreen' variants={variants}>
            <div className='bg-[#0f0f0f] py-24 sm:py-32'>
                <motion.div
                    initial='offscreen'
                    whileInView='onscreen'
                    viewport={{ once: false, amount: 0 }}
                    variants={variants}>
                    <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                        <div className='mx-auto max-w-2xl lg:max-w-none'>
                            <div className='text-center'>
                                <AnimatedText text={t('index.perfect_tech')} delayPerWord={0.08} />
                                <motion.p
                                    initial='offscreen'
                                    whileInView='onscreen'
                                    variants={variants}
                                    viewport={{ once: false, amount: 0 }}>
                                    <a href='https://waap.phylaxis.net/'>
                                        <div className='sm:mb-8 flex sm:justify-center'>
                                            <div className='mt-8 relative rounded-full px-3 py-1 text-sm leading-6 text-white ring-1 ring-white/80 hover:ring-gray-300 transition-all ease-in-out duration-150 shadow-white'>
                                                {t('index.waap_detail')}{' '}
                                                <a href='https://waap.phylaxis.net' className='font-semibold text-phl-secondary-2'>
                                                    <span className='absolute inset-0' aria-hidden='true' />
                                                    <span aria-hidden='true'>&rarr;</span>
                                                </a>
                                            </div>
                                        </div>
                                    </a>
                                </motion.p>
                            </div>
                            <div className='grid lg:grid-cols-2 gap-10 rounded-2xl mt-16 sm:grid-cols-1'>
                                <motion.div
                                    variants={contVar(0.1)}
                                    initial='hidden'
                                    whileInView='visible'
                                    viewport={{ once: false, amount: 0 }}>
                                    <div className='grid grid-cols-2 gap-6'>
                                        <img
                                            src='/img/p-waap.png'
                                            alt='Phylaxis WAAP'
                                            className='w-[200px] object-cover rounded-lg'
                                        />
                                        <div className='flex flex-col justify-center'>
                                            <h2 className='text-xl font-medium tracking-tight text-white'>
                                                Phylaxis WAAP
                                            </h2>
                                            <p className='text-gray-400 text-sm'>{t('index.phylaxis_waap_detail')}</p>
                                        </div>
                                    </div>
                                </motion.div>
                                <motion.div
                                    variants={contVar(0.2)}
                                    whileInView='visible'
                                    initial='hidden'
                                    viewport={{ once: false, amount: 0 }}>
                                    <div className='grid grid-cols-2 gap-6'>
                                        <img
                                            src='/img/p-dddetect.png'
                                            alt='Phylaxis WAAP'
                                            className='w-[200px] object-cover rounded-lg'
                                        />
                                        <div className='flex flex-col justify-center'>
                                            <h2 className='text-xl font-medium tracking-tight text-white'>
                                                Phylaxis DD-Detect Engine
                                            </h2>
                                            <p className='text-gray-400 text-sm'>{t('index.dd_detect_detail')}</p>
                                        </div>
                                    </div>
                                </motion.div>
                                <motion.div
                                    variants={contVar(0.3)}
                                    whileInView='visible'
                                    initial='hidden'
                                    viewport={{ once: false, amount: 0 }}>
                                    <div className='grid grid-cols-2 gap-6'>
                                        <img
                                            src='/img/p-optcache.png'
                                            alt='Phylaxis WAAP'
                                            className='w-[200px] object-cover rounded-lg'
                                        />
                                        <div className='flex flex-col justify-center'>
                                            <h2 className='text-xl font-medium tracking-tight text-white'>
                                                Phylaxis OPT-Cache Engine
                                            </h2>
                                            <p className='text-gray-400 text-sm'>{t('index.opt_cache_detail')}</p>
                                        </div>
                                    </div>
                                </motion.div>
                                <motion.div
                                    variants={contVar(0.4)}
                                    whileInView='visible'
                                    initial='hidden'
                                    viewport={{ once: false, amount: 0 }}>
                                    <div className='grid grid-cols-2 gap-6'>
                                        <img
                                            src='/img/p-rtstrm.png'
                                            alt='Phylaxis WAAP'
                                            className='w-[200px] object-cover rounded-lg'
                                        />
                                        <div className='flex flex-col justify-center'>
                                            <h2 className='text-xl font-medium tracking-tight text-white'>
                                                Phylaxis RT-STRM
                                            </h2>
                                            <p className='text-gray-400 text-sm'>{t('index.rt_strm_detail')}</p>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    );
}
