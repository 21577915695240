import { Union, tw } from "../Utils";
import { IChildrenProps, IChildrenPropsWithClassName } from "../Interfaces";
import * as Dom from "react-router-dom";

interface IButtonGroup extends IChildrenPropsWithClassName {}

const buttonGroup = tw.style({
  display: "flex",
  flexDirection: "flex-row",
  "@max-sm": {
    flexDirection: "max-sm:flex-col",
  },
});

export const ButtonGroup = ({ children, className }: IButtonGroup) => {
  return <div className={Union(buttonGroup.class, className)}>{children}</div>;
};

interface IButton {
  onClick?: any;
  text: string;
  link?: string;
  className?: string;
  size?: "sm" | "md" | "slim" | "md-bold";
}

interface IArrowButton extends Omit<IButton, "text"> {
  text?: string;
  right?: boolean;
  left?: boolean;
  style?: "thin";
}

/* ---- Size ---- */
const buttonSm = tw.style({
  paddingX: "px-4",
  paddingY: "py-2",
  borderRadius: "rounded-md",
  "@max-sm": {
    paddingY: "max-sm:py-3",
  },
});

const buttonMd = tw.style({
  paddingX: "px-6",
  paddingY: "py-5",
  borderRadius: "rounded-md",
  lineHeight: "leading-none",
  "@max-sm": {
    paddingY: "max-sm:py-3",
    fontSize: "max-sm:text-base",
  },
});

const buttonSlim = tw.style({
  paddingX: "px-4",
  paddingY: "py-2.5",
  fontSize: "text-sm",
  lineHeight: "leading-none",
  borderRadius: "rounded-md",
  borderWidth: "border",
  borderColor: "border-phl-primary",
});

const buttonMdBold = tw.style({
  fontWeight: "font-semibold",
  borderWidth: "border-2",
  paddingX: "px-6",
  paddingY: "py-5",
  borderRadius: "rounded-md",
  lineHeight: "leading-none",
  fontSize: "text-lg",
  "@max-sm": {
    paddingY: "max-sm:py-3",
    fontSize: "max-sm:text-base",
  },
});
/* ---- Size End ---- */

/* ---- Color ---- */
// 규칙: 배경색 - 텍스트색
const buttonSecondary = tw.style({
  backgroundColor: "bg-phl-secondary-1",
});

const buttonWhitePrimary = tw.style({
  color: "text-phl-secondary-1",
});

const buttonPrimary = tw.style({
  color: "text-white",
  backgroundColor: "bg-phl-primary",
});

const buttonTransparencyWhite = tw.style({
  backgroundColor: "bg-slate-900",
  color: "text-slate-50",
  transition: "transition ease-[1s]",
  ":hover": {
    //shadow
    boxShadow: "hover:shadow-lg",
    transition: "hover:transition ease-[1s]",
  }
});

const arrowButton = tw.style({
  fontSize: "text-2xl",
  fontWeight: "font-bold",
  paddingX: "px-3",
  paddingY: "py-3",
  lineHeight: "leading-none",
  borderWidth: "border-2",
  borderRadius: "rounded-md",
  ":hover": {
    color: "hover:text-gray-200",
  },

  "@max-sm": {
    borderWidth: "max-sm:border",
    paddingX: "max-sm:px-2",
    paddingY: "max-sm:py-1",
    fontSize: "max-sm:text-lg",
    fontWeight: "max-sm:font-normal",
  },
});

const arrowButtonThin = tw.style({
  lineHeight: "leading-none",
  borderRadius: "rounded-md",
  ":hover": {
    color: "hover:text-gray-200",
  },
  borderWidth: "border",
  paddingX: "px-2",
  paddingY: "py-1",
  fontSize: "text-lg",
  fontWeight: "font-normal",
});
/* ---- Color End ---- */

export const ButtonSecondary = ({ text, onClick, link, className, size }: IButton) => {
  let style;
  switch (size) {
    case "sm":
      style = tw.mergeProps(buttonSecondary.style, buttonSm.style);
      break;
    case "md":
      style = tw.mergeProps(buttonSecondary.style, buttonMd.style);
      break;
    case "slim":
      style = tw.mergeProps(buttonSecondary.style, buttonSlim.style);
      break;
    default:
      style = tw.mergeProps(buttonSecondary.style, buttonSm.style);
      break;
  }
  return (
    <button className={Union(style, className)} onClick={onClick}>
      <Link to={link}>{text}</Link>
    </button>
  );
};

export const ButtonWhitePrimary = ({ text, onClick, link, className, size }: IButton) => {
  let style;
  switch (size) {
    case "sm":
      style = tw.mergeProps(buttonWhitePrimary.style, buttonSm.style);
      break;
    case "md":
      style = tw.mergeProps(buttonWhitePrimary.style, buttonMd.style);
      break;
    case "slim":
      style = tw.mergeProps(buttonWhitePrimary.style, buttonSlim.style);
      break;
    default:
      style = tw.mergeProps(buttonWhitePrimary.style, buttonSm.style);
      break;
  }
  return (
    <button className={Union(style, className)} onClick={onClick}>
      <Link to={link}>{text}</Link>
    </button>
  );
};

export const ButtonPrimary = ({ text, onClick, link, className, size }: IButton) => {
  let style;
  switch (size) {
    case "sm":
      style = tw.mergeProps(buttonPrimary.style, buttonSm.style);
      break;
    case "md":
      style = tw.mergeProps(buttonPrimary.style, buttonMd.style);
      break;
    case "slim":
      style = tw.mergeProps(buttonPrimary.style, buttonSlim.style);
      break;
    default:
      style = tw.mergeProps(buttonPrimary.style, buttonSm.style);
      break;
  }
  return (
    <button className={Union(style, className)} onClick={onClick}>
      <Link to={link}>{text}</Link>
    </button>
  );
};

export const ButtonTransparencyWhite = ({ text, onClick, link, className, size }: IButton) => {
  let style;
  switch (size) {
    case "sm":
      style = tw.mergeProps(buttonTransparencyWhite.style, buttonSm.style);
      break;
    case "md":
      style = tw.mergeProps(buttonTransparencyWhite.style, buttonMd.style);
      break;
    case "slim":
      style = tw.mergeProps(buttonTransparencyWhite.style, buttonSlim.style);
      break;
    case "md-bold":
      style = tw.mergeProps(buttonTransparencyWhite.style, buttonMdBold.style);
      break;
    default:
      style = tw.mergeProps(buttonTransparencyWhite.style, buttonSm.style);
      break;
  }
  return (
    <button className={Union(style, className)} onClick={onClick}>
      <Link to={link}>{text}</Link>
    </button>
  );
};

export const ArrowButton = ({ onClick, link, className, right, left, style }: IArrowButton) => {
  let c;
  switch (style) {
    case "thin":
      c = arrowButtonThin.class;
      break;
    default:
      c = arrowButton.class;
      break;
  }

  return (
    <button className={Union(c, className)} onClick={onClick}>
      <Link to={link}>{right || !left ? "→" : "←"}</Link>
    </button>
  );
};

interface ILink extends IChildrenProps {
  to?: string;
}

export const Link = ({ to, children }: ILink) => {
  if (to) {
    return <Dom.Link to={to}>{children}</Dom.Link>;
  }
  return <>{children}</>;
};
