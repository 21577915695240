import { Union, tw } from "../Utils";
import { IChildrenProps, IChildrenPropsWithClassName } from "../Interfaces";
import * as Dom from "react-router-dom";
import * as tailwindest from "tailwindest";

const 부가설명 = tw.style({
  fontWeight: "font-bold",
  color: "text-gray-500",
  marginBottom: "mb-4",
  fontSize: "text-base",
  "@max-sm": {
    fontSize: "max-sm:text-sm",
  },
});

const 내용 = tw.style({
  color: "text-phl-primary",
  marginBottom: "mb-10",
  fontSize: "text-lg",
  "@max-sm": {
    fontSize: "max-sm:text-base",
  },
});

const 제목 = tw.style({
  fontSize: "text-4xl",
  fontWeight: "font-extrabold",
  color: "text-phl-primary",
  marginBottom: "mb-4",
  "@max-sm": {
    fontSize: "max-sm:text-3xl",
    marginBottom: "max-sm:mb-1",
  },
});

const 강조제목 = tw.style({
  fontWeight: "font-extrabold",
  color: "text-phl-primary",
  fontSize: "text-5xl",
  marginBottom: "mb-6",
  "@max-sm": {
    marginBottom: "max-sm:mb-4",
    fontSize: "max-sm:text-4xl",
  },
});

interface IText extends IChildrenProps {
  className?: string;
  size: "sm" | "md" | "lg" | "xl";
  style?: any;
}

export const Text = ({ size, style, className, children }: IText) => {
  const textClass = (() => {
    switch (size) {
      case "sm":
        return 부가설명;
      case "md":
        return 내용;
      case "lg":
        return 제목;
      case "xl":
        return 강조제목;
    }
  })();

  style = (() => {
    if (style) return style;
    else return tw.style({});
  })();

  return <p className={Union(tw.mergeProps(textClass.style, style.style), className)}>{children}</p>;
};
