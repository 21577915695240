import React, { useState, useRef } from 'react';
import Navigation from '../Components/Navigation/Navigation';
import Header from '../Components/SlaContents/Header';
import Details from '../Components/SlaContents/Detail';
import Footer from '../Components/Footer';

const Sla = () => {
    return (
        <>
            <a id='top' />
            <Navigation />
            <Header />
            <Details />
            <Footer />
        </>
    );
};

export default Sla;
