import React from "react";
import "tailwindcss/tailwind.css";
import { Text } from "./../Texts";
import { ButtonGroup, ButtonSecondary, ButtonWhitePrimary } from "../Buttons";
import styled, { keyframes } from "styled-components";
import { tw } from "../../Utils";
import { Container } from "../Container";
import RoundedBox from "./RoundedBox";
import FeaturesTop from "./FeaturesTop";
import StrongInfra from "./StrongInfra";
import Caching from "./Caching";
import Essential from "./Essential";
import FeatureSSL from "./FeatureSSL";

const ContentCotainer = tw.style({
    display: "flex",
    alignItems: "items-center",
    flexDirection: "flex-row",
    textAlign: "text-left",
    "@max-sm": {
        flexDirection: "max-sm:flex-col",
        textAlign: "max-sm:text-center",
    },
    "@lg": {
        marginLeft: "lg:ml-12",
    },
});


const ContentCotainerB = tw.style({
    display: "flex",
    alignItems: "items-center",
    flexDirection: "flex-row",
    textAlign: "text-right",
    width: "w-full",
    "@max-sm": {
        flexDirection: "max-sm:flex-col",
        textAlign: "max-sm:text-center",
    },
    "@lg": {
        marginRight: "lg:mr-12",
    },
});

const ImageContainer = tw.style({
    display: "flex",
    flexDirection: "flex-col",
    justifyContent: "justify-center",
    borderRadius: "rounded-lg",
    width: "w-full",
    marginLeft: "ml-10",
    "@max-sm": {
        marginLeft: "max-sm:-ml-0",
        width: "max-sm:w-full",
    },
    "@md": {
        textAlign: "md:text-center",
        width: "md:w-1/2",
    },
    "@lg": {
        width: "lg:w-1/2",
    },
});

const flexContainer = tw.style({
    display: "flex",
    flexDirection: "flex-row",
    textAlign: "text-left",
    marginTop: "mt-12",
});

const flexContainerB = tw.style({
    display: "flex",
    flexDirection: "flex-row",
    textAlign: "text-right",
    marginTop: "mt-12",
});

const contentOuter = tw.style({
    backgroundColor: "bg-white",
    width: "w-full",
    overflow: "overflow-hidden",
    paddingBottom: "pb-16",
    padding: "p-6",
    // paddingTop: "pt-[90px]",
    position: "relative",
});

const contentOuterB = tw.style({
    backgroundColor: "bg-slate-100",
    width: "w-full",
    overflow: "overflow-hidden",
    paddingBottom: "pb-16",
    padding: "p-6",
    // paddingTop: "pt-[90px]",
    position: "relative",
});

const contentTitle = tw.style({
    fontWeight: "font-bold",
    "@max-sm": {
        marginRight: "max-sm:mr-0",
        fontSize: "max-sm:text-xl",
    },
    "@sm": {
        marginRight: "sm:mr-12",
        fontSize: "sm:text-2xl",
    },
    "@md": {
        fontSize: "md:text-3xl",
    },
    "@lg": {
        fontSize: "lg:text-4xl",
    },
});

const contentTitleB = tw.style({
    fontWeight: "font-bold",
    "@max-sm": {
        marginLeft: "max-sm:ml-0",
        fontSize: "max-sm:text-xl",
    },
    "@sm": {
        marginLeft: "sm:ml-20",
        fontSize: "sm:text-2xl",
    },
    "@md": {
        fontSize: "md:text-3xl",
    },
    "@lg": {
        fontSize: "lg:text-4xl",
    },
});


const ProxyFeatures = () => {
    return (
        <>
            <a id="caching">
            <Caching />
            </a>
            <FeaturesTop />
            <a id="ssl">
            <FeatureSSL />
            </a>
            <StrongInfra />
            <Essential />
            <RoundedBox />
        </>
    );
};

export default ProxyFeatures;