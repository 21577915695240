import React from 'react';
import 'tailwindcss/tailwind.css';
import { Text } from './../Texts';
import { ButtonGroup, ButtonSecondary, ButtonWhitePrimary } from '../Buttons';
import { Union, tw, handleOpenExternal } from '../../Utils';
import { IChildrenProps } from '../../Interfaces';
import { Container } from '../Container';

const contentOuter = tw.style({
    backgroundColor: 'bg-white',
    width: 'w-full',
    overflow: 'overflow-hidden',
    padding: 'p-6',
    paddingTop: 'pt-[90px]',
});

const ContentOuter = ({ children }: IChildrenProps) => {
    return <div className={contentOuter.class}>{children}</div>;
};

const emblemContainer = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    marginLeft: 'ml-auto',
    justifyContent: 'justify-center',
    marginRight: 'mr-8',
    '@max-md': {
        display: 'max-md:hidden',
    },
});

const emblem = tw.style({
    '@max-lg': {
        width: 'max-lg:w-[700px]',
    },
    '@lg': {
        width: 'lg:w-[600px]',
    },
});

const flexContainer = tw.style({
    display: 'flex',
    flexDirection: 'flex-row',
    textAlign: 'text-left',
    marginTop: 'mt-12',
});

const flexContainer2 = tw.style({
    display: 'flex',
    flexDirection: 'flex-row',
    textAlign: 'text-left',
});

const textBox = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    justifyContent: 'justify-center',
    width: 'w-full',
    '@max-sm': {
        textAlign: 'max-sm:text-center',
    },
    '@sm': {
        width: 'sm:w-full',
    },
    '@md': {
        width: 'md:w-2/3',
    },
    '@lg': {
        width: 'lg:w-1/2',
    },
});

const IrDetail = () => {
    return (
        <div className={contentOuter.class}>
            <Container>
                <div className={flexContainer.class}>
                    <div className={textBox.class}>
                        <Text size='lg' style={tw.style({ '@max-sm': { fontSize: 'max-sm:text-2xl' } })}>
                            2024년 07월 30일 기준 주주명부
                        </Text>
                        <Text size='md' className='font-semibold'>
                            2024년 7월 30일
                        </Text>
                    </div>
                </div>
                <div className='border-b border-slate-700 border-opacity-50'></div>
            </Container>
            <Container>
                <div className={flexContainer2.class}>
                    <div className={textBox.class}>
                        <Text size='sm'>필락시스 주식회사의 2024년 07월 30일 기준 주주명부를 공시합니다.</Text>
                        <table className='table-fixed w-full mb-10'>
                            <thead>
                                <tr>
                                    <th className='w-1/3 border border-slate-700 border-opacity-50'>주주</th>
                                    <th className='w-1/3 border border-slate-700 border-opacity-50'>주식수</th>
                                    <th className='w-1/3 border border-slate-700 border-opacity-50'>지분율</th>
                                    <th className='w-1/3 border border-slate-700 border-opacity-50'>비고</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='border border-slate-700 border-opacity-50'>한상엽</td>
                                    <td className='border border-slate-700 border-opacity-50'>83,840</td>
                                    <td className='border border-slate-700 border-opacity-50'>85%</td>
                                    <td className='border border-slate-700 border-opacity-50'>대표이사</td>
                                </tr>
                                <tr>
                                    <td className='border border-slate-700 border-opacity-50'>이수혁</td>
                                    <td className='border border-slate-700 border-opacity-50'>14,795</td>
                                    <td className='border border-slate-700 border-opacity-50'>15%</td>
                                    <td className='border border-slate-700 border-opacity-50'>사내이사</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='font-bold mb-5'>
                            위 주주명부는 본사에 비치된 주주명부와 대조하여 틀림없음을 증명합니다.
                        </div>
                        <div className='font-bold mb-5'>2024년 07월 30일</div>
                        <div className='font-bold mb-5'>
                            필락시스 주식회사
                            <br />
                            서울특별시 금천구 디지털로 9길 32, 제 비동 504-1호
                        </div>
                        <div className='font-bold mb-5'>대표이사 한상엽 (직인 생략)</div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default IrDetail;
