import Header from "../Components/Calculator/Header";
import Navigation from "../Components/Navigation/Navigation";
import Footer from "../Components/Footer";
import Content5 from "../Components/IndexContents/Content5";

const Calculator = () => {
    return (
        <>
            <Navigation />
            <Header />
            <Content5 />
            <Footer />
        </>
    );
};

export default Calculator;