import React from "react";
import Navigation from "../Components/Navigation/Navigation";
import Footer from "../Components/Footer";
import Hero from "../Components/Privacy/Hero";

const Privacy = () => {
    return (
        <>
            <a id="top" />
            <Navigation />
            <Hero />
            <div className="flex flex-col pl-20 pr-20">
                <div className="text-xl font-bold">
                    제1조 (목적)
                </div>
                <p>이 개인정보 처리방침은 필락시스 주식회사(이하 '회사')가 제공하는 클라우드 서비스와 관련하여 회사와 회원 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
                
                <div className="text-xl font-bold">
                    제2조 (수집하는 개인정보 항목)
                </div>
                <p>회사는 다음과 같은 개인정보 항목을 수집합니다.</p>
                <ul className="list-disc list-inside pl-5">
                    <li>이름, 이메일, 전화번호, 결제 정보(카드 정보, 주소)</li>
                </ul>
                
                <div className="text-xl font-bold">
                    제3조 (개인정보의 수집 및 이용 목적)
                </div>
                <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
                <ul className="list-disc list-inside pl-5">
                    <li>서비스 제공 및 고객 지원</li>
                    <li>마케팅 및 광고</li>
                </ul>
                
                <div className="text-xl font-bold">
                    제4조 (개인정보의 보유 및 이용 기간)
                </div>
                <p>회사는 개인정보 수집 및 이용 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 다만, 관계 법령에 따라 보존할 필요가 있는 경우에는 법령이 정한 기간 동안 보존합니다.</p>
                <p>보유 기간: 회원 탈퇴 시까지</p>
                
                <div className="text-xl font-bold">
                    제5조 (개인정보의 제3자 제공 및 위탁)
                </div>
                <p>회사는 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다. 다만, 아래의 경우는 예외로 합니다.</p>
                <ul className="list-disc list-inside pl-5">
                    <li>Google Analytics: 서비스 이용 및 통계 분석 목적으로 이용. 개인 식별 정보는 수집하지 않음. <a href="https://policies.google.com/privacy?hl=ko">Google 개인정보 처리방침</a></li>
                    <li>Microsoft Clarity 및 Microsoft Advertising: 행동 지표 및 마케팅 목적으로 이용. <a href="https://privacy.microsoft.com/ko-KR/privacystatement">Microsoft 개인정보 보호 성명</a></li>
                </ul>
                
                <div className="text-xl font-bold">
                    제6조 (이용자와 법정대리인의 권리와 그 행사 방법)
                </div>
                <p>이용자는 언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며, 수집, 이용, 제공에 대한 동의 철회 등을 요청할 수 있습니다.</p>
                <p>권리 행사 방법: 전자우편을 통하여 요청 (privacy@phylaxis.net)</p>
                
                <div className="text-xl font-bold">
                    제7조 (개인정보의 파기 절차 및 방법)
                </div>
                <p>개인정보 보유 기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.</p>
                <ul className="list-disc list-inside pl-5">
                    <li>파기 절차: 개인정보를 파기합니다.</li>
                    <li>파기 방법: 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제, 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각</li>
                </ul>
                
                <div className="text-xl font-bold">
                    제8조 (개인정보의 안전성 확보 조치)
                </div>
                <p>회사는 개인정보 보호를 위해 다음과 같은 조치를 취하고 있습니다.</p>
                <ul className="list-disc list-inside pl-5">
                    <li>비밀번호 단방향 암호화</li>
                    <li>데이터베이스 통신 과정 암호화 및 웹사이트 통신 시 TLS 보안 통신 적용</li>
                    <li>생체 인증을 통한 서버 접근 제한 및 상시 폐쇄회로 CCTV 모니터링</li>
                    <li>기타 기술적, 관리적, 물리적 조치</li>
                </ul>
                
                <div className="text-xl font-bold">
                    제9조 (개인정보 자동 수집 장치의 설치, 운영 및 그 거부에 관한 사항)
                </div>
                <p>회사는 이용자에게 서비스를 제공하기 위해 쿠키를 사용합니다.</p>
                <ul className="list-disc list-inside pl-5">
                    <li>쿠키의 사용 목적: 방문 및 이용 형태 파악, 맞춤형 정보 제공</li>
                    <li>쿠키의 설치 및 운영: 웹브라우저 설정을 통해 쿠키 저장을 거부할 수 있음</li>
                    <li>쿠키 저장 거부 시 서비스 이용에 제한이 있을 수 있음</li>
                </ul>
                
                <div className="text-xl font-bold">
                    제10조 (개인정보 보호책임자 및 담당자의 연락처)
                </div>
                <p>개인정보 보호책임자 및 담당자의 연락처는 다음과 같습니다.</p>
                <ul className="list-disc list-inside pl-5">
                    <li>개인정보 보호책임자: 이수혁, 이메일: privacy@phylaxis.net</li>
                    <li>운영팀 담당자: 이수혁, 이메일: privacy@phylaxis.net</li>
                </ul>
                
                <div className="text-xl font-bold">
                    제11조 (고지 의무)
                </div>
                <p>이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경 내용의 추가, 삭제 및 수정이 있을 시에는 변경사항의 시행 7일 전부터 공지사항을 통해 고지할 것입니다.</p>
                
                <div className="text-xl font-bold">
                    제12조 (개인정보 침해 구제 방법)
                </div>
                <p>정보주체는 개인정보침해로 인한 구제를 받기 위하여 아래 기관에 분쟁해결이나 상담 등을 신청할 수 있습니다.</p>
                <ul className="list-disc list-inside pl-5">
                    <li>개인정보분쟁조정위원회: (국번없이) 1833-6972, www.kopico.go.kr</li>
                    <li>개인정보침해신고센터: (국번없이) 118, privacy.kisa.or.kr</li>
                    <li>대검찰청: (국번없이) 1301, www.spo.go.kr</li>
                    <li>경찰청: (국번없이) 182, ecrm.cyber.go.kr</li>
                </ul>
                <p>기타 개인정보침해의 신고, 상담에 대하여는 위 기관에 문의하시기 바랍니다.</p>
                
                <div className="text-xl font-bold pt-10">
                    부칙
                </div>
                <p>제1조 (시행일)</p>
                <p>본 약관은 2024.02.26부터 시행됩니다.</p>

                <div className="pb-15"/>
            </div>
            <Footer />
        </>
    );
};

export default Privacy;