import React from "react";
import "tailwindcss/tailwind.css";
import { Text } from "../Texts";
import { ButtonGroup, ButtonSecondary, ButtonWhitePrimary } from "../Buttons";
import { Union, tw, handleOpenExternal } from "../../Utils";
import { IChildrenProps } from "../../Interfaces";
import { Container } from "../Container";

const contentOuter = tw.style({
    backgroundColor: "bg-white",
    width: "w-full",
    overflow: "overflow-hidden",
    padding: "p-6",
    paddingTop: "pt-[90px]",
});

const ContentOuter = ({ children }: IChildrenProps) => {
    return <div className={contentOuter.class}>{children}</div>;
};

const emblemContainer = tw.style({
    display: "flex",
    flexDirection: "flex-col",
    marginLeft: "ml-auto",
    justifyContent: "justify-center",
    marginRight: "mr-8",
    "@max-md": {
        display: "max-md:hidden",
    },
});

const emblem = tw.style({
    "@max-lg": {
        width: "max-lg:w-[700px]",
    },
    "@lg": {
        width: "lg:w-[600px]",
    },
});

const flexContainer = tw.style({
    display: "flex",
    flexDirection: "flex-row",
    textAlign: "text-left",
    marginTop: "mt-12",
});


const flexContainer2 = tw.style({
    display: "flex",
    flexDirection: "flex-row",
    textAlign: "text-left",
});

const textBox = tw.style({
    display: "flex",
    flexDirection: "flex-col",
    justifyContent: "justify-center",
    width: "w-full",
    paddingRight: "pr-8",
    paddingLeft: "pl-8",
    "@max-sm": {
        textAlign: "max-sm:text-center",
    },
});

const AnnouncementDetail = () => {
    return (
        <div className={contentOuter.class}>
            <Container>
                <div className={flexContainer.class}>
                    <div className={textBox.class}>
                        <Text size="lg" style={tw.style({ "@max-sm": { fontSize: "max-sm:text-2xl" } })}>
                            SEL-01 센터 점검에 관한 건
                        </Text>
                        <Text size="md" className="font-semibold">
                            2023년 1월 5일
                        </Text>
                    </div>
                </div>
                <div className="border-b border-slate-700 border-opacity-50"></div>
            </Container>
            <Container>
                <div className={flexContainer2.class}>
                    <div className={textBox.class}>
                        2024년 1월 8일, 당사 SEL-01 센터에서 점검이 진행될 예정입니다.
                        <br />
                        <br />
                        점검 기간 동안, SEL-01 센터의 서비스가 일시 중단될 예정입니다.
                        <br />
                        <br />
                        감사합니다.
                        <br />
                        <br />
                        <div className="flex flex-row font-semibold text-lg text-gray-600">
                            <img src="/img/LogoAllGray.svg" alt="logo" className="w-24 mr-2" />팀 드림
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default AnnouncementDetail;