import { ChevronRightIcon } from '@heroicons/react/20/solid';

export default function Example() {
    return (
        <div className='relative isolate overflow-hidden bg-gray-900'>
            <svg
                className='absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]'
                aria-hidden='true'>
                <defs>
                    <pattern
                        id='983e3e4c-de6d-4c3f-8d64-b9761d1534cc'
                        width={200}
                        height={200}
                        x='50%'
                        y={-1}
                        patternUnits='userSpaceOnUse'>
                        <path d='M.5 200V.5H200' fill='none' />
                    </pattern>
                </defs>
                <svg x='50%' y={-1} className='overflow-visible fill-gray-800/20'>
                    <path
                        d='M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z'
                        strokeWidth={0}
                    />
                </svg>
                <rect width='100%' height='100%' strokeWidth={0} fill='url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)' />
            </svg>
            <div
                className='absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]'
                aria-hidden='true'>
                <div
                    className='aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20'
                    style={{
                        clipPath:
                            'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                    }}
                />
            </div>
            <div className='mx-auto max-w-7xl px-6 pb-10 pt-10 sm:pb-10 lg:flex lg:px-8 lg:py-20'>
                <div className='mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl'>
                    <img className='h-11' src='/img/EmblemWhite.svg' alt='Phylaxis' />
                    <div className='mt-24 sm:mt-32 lg:mt-16'>
                        <a href='/announcement/board/3' className='inline-flex space-x-6'>
                            <span className='rounded-full bg-phl-secondary-2/10 px-3 py-1 text-sm font-semibold leading-6 text-phl-secondary-2 ring-1 ring-inset ring-phl-secondary-2/20'>
                                새 소식
                            </span>
                            <span className='inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300'>
                                <span>제품 개선 (Portal 배포) 안내</span>
                                <ChevronRightIcon className='h-5 w-5 text-gray-500' aria-hidden='true' />
                            </span>
                        </a>
                    </div>
                    <h1 className='mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl'>
                        통합된 엔드포인트 관리, <br className='hidden sm:inline' />
                        하나의 플랫폼에서.
                    </h1>
                    <p className='mt-6 text-base leading-7 text-gray-300'>
                        Phylaxis WAAP는 API, 애플리케이션 엔드포인트 관리를 위한
                        <br />
                        통합된 플랫폼을 제공합니다. <br />
                        최고 수준의 API 보안과 WAAP(차세대 WAF)은 물론, <br />
                        고급 캐시 정책, DDoS 방어, SSL 관리 등 다양한 기능을 제공합니다.
                    </p>
                    <div className='mt-10 flex items-center gap-x-6'>
                        <a
                            href='https://portal.phylaxis.net/signin'
                            className='rounded-md bg-phl-secondary-1 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-phl-secondary-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400 transition-all ease-in-out duration-150'>
                            Portal 접속
                        </a>
                        <a href='/inquiry' className='text-sm font-semibold leading-6 text-white'>
                            도입 문의 <span aria-hidden='true'>→</span>
                        </a>
                    </div>
                </div>
                {/* align right */}
                <div className='mx-auto flex max-w-2xl lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-3xl lg:ml-auto lg:mr-0 align-right items-center'>
                    <div className='max-w-3xl flex-none sm:max-w-5xl lg:max-w-none'>
                        <img
                            src='/img/content/zone_manage.png'
                            alt='App screenshot'
                            className='w-[40rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10 opacity-80'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
