import React from 'react';

import Marquee from 'react-fast-marquee';

const Mq = () => {
    return (
        <Marquee
            style={{
                backgroundColor: '#15171a',
                padding: '10px 0',
                fontSize: '14px',
                color: '#fff',
            }}
            pauseOnHover={true}
            speed={20}
            autoFill={true}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <p className='ml-40'>
                    이용자 설문 조사 진행중, 경품 제공{' '}
                    <span arria-hidden='true' className='font-semibold'>
                        {' '}
                        <a href='https://tally.so/r/n0LBb0' target='_blank'>
                            설문 참여 →
                        </a>
                    </span>
                </p>
            </div>
        </Marquee>
    );
};

export default Mq;
