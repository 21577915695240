import { CloudArrowUpIcon, CubeTransparentIcon, BoltIcon } from '@heroicons/react/20/solid'

export default function FeaturesTop() {
    const features = [
        {
            name: '클릭 몇 번으로 적용.',
            description:
                '어려운 설정 없이도, 클릭 몇 번으로 적용할 수 있습니다. 또한, Phylaxis는 모든 웹사이트에 적용할 수 있습니다.',
            icon: CloudArrowUpIcon,
        },
        {
            name: '별다른 코딩 없이.',
            description: '어려운 코딩 없이도, 최소 1분만에 Phylaxis를 적용할 수 있습니다.',
            icon: CubeTransparentIcon,
        },
        {
            name: '빠르게, 더 빠르게.',
            description: 'Phylaxis 에지에 캐싱되어 있는 콘텐츠는, 평균보다 약 30% 빠르게 전송됩니다.',
            icon: BoltIcon,
        },
    ]
    return(
        <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div className="lg:pr-8 lg:pt-4">
                    <div className="lg:max-w-lg">
                        <h2 className="text-base font-semibold leading-7 text-phl-secondary-1">쉬운 사용.</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-phl-primary-dim sm:text-4xl">모든 웹사이트에 간단하게.</p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">저희 인프라는 고급 캐싱 기술, 최적화된 데이터베이스 관리 시스템,<br/>
                        그리고 강력한 서버 구성을 통해 높은 처리 속도와 안정성을 제공합니다.
                        </p>
                        <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                            {features.map((feature) => (
                                <div key={feature.name} className="relative pl-9">
                                    <dt className="inline font-semibold text-gray-900">
                                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-phl-secondary-1" aria-hidden="true" />
                                        {feature.name}
                                    </dt>{' '}
                                    <dd className="inline">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
                <img
                    src="/img/portal_overview.png"
                    alt="Product screenshot"
                    className="w-[48rem] max-w-none sm:w-[57rem] md:-ml-4 lg:-ml-0"
                    width={2432}
                    height={1442}
                />
            </div>
        </div>
    </div>
    )
}