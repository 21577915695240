import React, { useState, useRef } from 'react';
import Navigation from '../Components/Navigation/Navigation';
import NotFoundContent from '../Components/404Content';
import { useStrictMediaQuery } from '../Utils/hooks';
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
    return (
        <>
            <Helmet>
                <title>Phylaxis: 404 Not Found</title>
                <meta name='og:title' content='Phylaxis: 404 Not Found' />
                <meta name='og:description' content='페이지를 찾을 수 없어요: Phylaxis' />
                <meta name='description' content='페이지를 찾을 수 없어요: Phylaxis' />
            </Helmet>
            <a id='top' />
            <Navigation />
            <NotFoundContent />
            <Footer />
        </>
    );
};

export default NotFoundPage;
