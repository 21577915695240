import React, { useState, useRef } from "react";
import Navigation from "../Components/Navigation/Navigation";
import Content from "../Components/IntroContents/Content";
import { useStrictMediaQuery } from "../Utils/hooks";
import Footer from "../Components/Footer";
import IrJumbo from "../Components/IrContents/Jumbo";
import Board from "../Components/IrContents/Board";
import Hero from "../Components/IrContents/Hero";
import { Helmet } from "react-helmet";

const IrBoard = () => {
  return (
    <>
      <Helmet>
        <title>Phylaxis: IR</title>
        <meta name="og:title" content="Phylaxis: IR" />
        <meta name="og:description" content="IR Board를 확인하세요: Phylaxis" />
        <meta name="description" content="IR Board를 확인하세요: Phylaxis" />
      </Helmet>
      <a id="top" />
      <Navigation />
      <Hero />
      <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200"></hr>
      <Board />
      <Footer />
    </>
  );
};

export default IrBoard;