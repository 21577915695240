export default function Hero() {
    return (
        <div className='bg-white py-12'>
            <div className='px-24'>
                <h2 className='text-2xl font-bold tracking-tight text-gray-900'>Phylaxis WAAP 서비스 수준 협약</h2>
                <h4 className='text-xl font-light tracking-tight text-gray-900'>최종 업데이트: 2024년 5월 27일</h4>
            </div>
            <div className='px-24 pt-8'>
                <p className='text-lg font-semibold'>1. 목적</p>
                <p className='text-lg leading-8 text-gray-600'>
                    본 Phylaxis WAAP 서비스 수준 협약(Service Level Agreement, "SLA")은 필락시스 주식회사(Phylaxis Inc.
                    USA) 및 그 관계사와 Phylaxis 서비스 사용자(귀하) 간의 Phylaxis 고객 계약 조건에 따라 Phylaxis WAAP의
                    사용을 규율하는 정책입니다. 본 SLA는 Phylaxis WAAP을 사용하는 각 계정에 별도로 적용됩니다. 본 계약에
                    달리 명시되어 있지 않는 한 본 SLA에는 Phylaxis 계약 조건이 적용되며, 당사는 Phylaxis 계약에 따라 본
                    SLA의 조건을 변경할 권리를 보유합니다.
                </p>
                <p className='text-lg font-semibold'>2. 정의</p>
                <p className='text-lg leading-8 text-gray-600'>
                    - "다운타임"은 Phylaxis WAAP에서 서비스가 불가능한 시간을 말합니다. Phylaxis WAAP 서비스 다운타임은
                    월별 청구 주기의 각 5분 간격에 대한 작동 시간 중 50% 이상을 소모하는 경우를 말합니다. Phylaxis
                    WAAP의 문제 외의 사유로 직접 또는 간접적으로 발생되는 다운타임은 포함되지 않습니다.
                    <br />- "월간 가동률"은 월별 청구 주기에서 각 5분 간격의 평균 오류율을 100%에서 뺀 값을 말합니다.{' '}
                    <br />- "서비스 보상"은 아래에 명시된 대로 지급되며, 적격의 계정에 적용됩니다.
                </p>
                <p className='text-lg font-semibold'>3. 서비스 보상</p>
                <p className='text-lg leading-8 text-gray-600'>
                    다운타임이 발생하는 경우, 당사는 귀하에게 서비스 보상을 지급합니다. 서비스 보은 다음과 같습니다.
                </p>
                <table className='table-auto mt-4 border-collapse border border-gray-300'>
                    <thead>
                        <tr className='bg-gray-100'>
                            <th className='px-4 py-2 text-left font-bold text-gray-700 border border-gray-300'>
                                월간 가동률
                            </th>
                            <th className='px-4 py-2 text-left font-bold text-gray-700 border border-gray-300'>
                                서비스 보상
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='px-4 py-2 border border-gray-300'>99.9% 미만, 99.0% 이상</td>
                            <td className='px-4 py-2 border border-gray-300'>계약 기간의 10% 연장</td>
                        </tr>
                        <tr className='bg-gray-50'>
                            <td className='px-4 py-2 border border-gray-300'>99.0% 미만, 95.0% 이상</td>
                            <td className='px-4 py-2 border border-gray-300'>계약 기간의 25% 연장</td>
                        </tr>
                        <tr>
                            <td className='px-4 py-2 border border-gray-300'>95.0% 미만</td>
                            <td className='px-4 py-2 border border-gray-300'>계약 기간의 100% 연장</td>
                        </tr>
                    </tbody>
                </table>
                <p className='text-lg font-semibold'>4. 적격 계정</p>
                <p className='text-lg leading-8 text-gray-600'>
                    서비스 보상을 받으려면, support@phylaxis.net 에 이메일을 전송해야 합니다. 서비스 보상 자격을 갖추기
                    위해서는 사건이 발생한 후 두 번째 청구 주기 말까지 크레딧 요청이 당사에 접수되어야 하며, 청구
                    메일에는 다음 사항을 기재해야 합니다:
                </p>
                <ul className='list-disc ml-4 space-y-2 text-gray-600'>
                    <li>제목에 "SLA 보상 요청" 이라는 단어</li>
                    <li>사용자가 청구하는 사건의 발생 날짜 및 시간, 그리고 지속 시간</li>
                    <li>사용자의 이메일 주소</li>
                    <li>사용자의 이름</li>
                    <li>
                        오류를 기록하고 청구하는 가동 불능을 증명하는 요청 로그(기밀 또는 민감한 정보는 마스킹
                        처리되어야 함).
                    </li>
                </ul>
                <p className='text-lg font-semibold'>5. 제외 경우</p>
                <p className='text-lg leading-8 text-gray-600'>서비스 약정은 다음에 해당하는 경우 적용되지 않습니다:</p>
                <ul className='list-disc ml-4 space-y-2 text-gray-600'>
                    <li>귀하의 작위 또는 부작위로 인한 경우</li>
                    <li>귀하의 장비 또는 소프트웨어, 기타 기술로 인한 경우</li>
                    <li>Phylaxis 계약에 따라 귀하의 Phylaxis WAAP 사용 권리를 당사가 중단 및 종료한 경우</li>
                    <li>
                        이외의 다운타임 계산에 다른 요인이 개입된 경우, 당사는 재량으로 그러한 요인을 고려하여 서비스
                        보상을 결정할 수 있습니다.
                    </li>
                </ul>
            </div>
        </div>
    );
}
