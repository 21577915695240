import React from "react";
import "tailwindcss/tailwind.css";
import { Text } from "../Texts";
import { ButtonGroup, ButtonSecondary, ButtonWhitePrimary } from "../Buttons";
import { Union, tw, handleOpenExternal } from "../../Utils";
import { IChildrenProps } from "../../Interfaces";
import { Container } from "../Container";

const contentOuter = tw.style({
    backgroundColor: "bg-white",
    width: "w-full",
    overflow: "overflow-hidden",
    padding: "p-6",
    paddingTop: "pt-[90px]",
});

const ContentOuter = ({ children }: IChildrenProps) => {
    return <div className={contentOuter.class}>{children}</div>;
};

const emblemContainer = tw.style({
    display: "flex",
    flexDirection: "flex-col",
    marginLeft: "ml-auto",
    justifyContent: "justify-center",
    marginRight: "mr-8",
    "@max-md": {
        display: "max-md:hidden",
    },
});

const emblem = tw.style({
    "@max-lg": {
        width: "max-lg:w-[700px]",
    },
    "@lg": {
        width: "lg:w-[600px]",
    },
});

const flexContainer = tw.style({
    display: "flex",
    flexDirection: "flex-row",
    textAlign: "text-left",
    marginTop: "mt-12",
});


const flexContainer2 = tw.style({
    display: "flex",
    flexDirection: "flex-row",
    textAlign: "text-left",
});

const textBox = tw.style({
    display: "flex",
    flexDirection: "flex-col",
    justifyContent: "justify-center",
    width: "w-full",
    paddingRight: "pr-8",
    paddingLeft: "pl-8",
    "@max-sm": {
        textAlign: "max-sm:text-center",
    },
});

const AnnouncementDetail = () => {
    return (
        <div className={contentOuter.class}>
            <Container>
                <div className={flexContainer.class}>
                    <div className={textBox.class}>
                        <Text size="lg" style={tw.style({ "@max-sm": { fontSize: "max-sm:text-2xl" } })}>
                            Phylaxis WAAP) 정식 출시 일자 및 Spec 안내
                        </Text>
                        <Text size="md" className="font-semibold">
                            2024년 2월 22일
                        </Text>
                    </div>
                </div>
                <div className="border-b border-slate-700 border-opacity-50"></div>
            </Container>
            <Container>
                <div className={flexContainer2.class}>
                    <div className={textBox.class}>
                        안녕하세요,<br/>
                        <br/>
                        지난 23년 6월부터, 많은 분들께서 Phylaxis의 첫 제품인 Phylaxis WAAP를 기다려주셨습니다.<br/>
                        <br/>
                        그간 지속적인 성원을 보내주신 여러분께 감사드리며, 아래와 같이 출시 관련 일정 등의 내용을 공지합니다:<br/>
                        <br/>
                        - 출시일자: 2024년 2월 26일 (월)<br/>
                        - 요금정책: https://phylaxis.net/waap/pricing<br/>
                        - 제품상세: https://phylaxis.net/waap/about<br/>
                        - WAAP 성능 지표:<br/>
                        -- HTTPs Mitigation up to 10M requests/s (Single Zone, Pro Plan)<br/>
                        -- HTTP Mitigation up to TBD<br/>
                        -- TTM (80%) under 3s<br/>
                        -- TTM (80%, UAM) under 0.2s<br/>
                        이 외 성능 지표는 상담 채널을 통해 문의 바랍니다.<br/>
                        <br/>
                        감사합니다.<br/>
                        <div className="flex flex-row font-semibold text-lg text-gray-600">
                            <img src="/img/LogoAllGray.svg" alt="logo" className="w-24 mr-2" />팀 드림
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default AnnouncementDetail;