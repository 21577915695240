import React from 'react';
import 'tailwindcss/tailwind.css';
import { Text } from './../Texts';
import { ButtonGroup, ButtonSecondary, ButtonWhitePrimary } from '../Buttons';
import { tw } from '../../Utils';
import { Container } from '../Container';

const aContainer = tw.style({
    width: 'w-full',
    display: 'flex',
    flexDirection: 'flex-row',
    justifyContent: 'justify-center',
    alignItems: 'items-center',
    '@max-md': {
        flexDirection: 'max-md:flex-col',
    },
});

const TableSection = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    justifyContent: 'justify-center',
    alignItems: 'items-start',
    width: 'w-full',
});

const TableContainer = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    justifyContent: 'justify-center',
    alignItems: 'items-start',
    width: 'w-full',
    marginBottom: 'mb-10',
    transition: 'transition ease-[0.5s]',
    borderRadius: 'rounded-lg',
    padding: 'p-6',
    ':hover': {
        backgroundColor: 'hover:bg-slate-100',
        transition: 'hover:transition ease-[0.5s]',
        cursor: 'hover:cursor-pointer',
    },
});

const contentOuter = tw.style({
    backgroundColor: 'bg-white',
    width: 'w-full',
    overflow: 'overflow-hidden',
    paddingBottom: 'pb-16',
    padding: 'p-6',
});

const flexContainer = tw.style({
    display: 'flex',
    flexDirection: 'flex-row',
    textAlign: 'text-left',
    marginTop: 'mt-12',
});

const textBox = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    justifyContent: 'justify-center',
    width: 'w-full',
    '@max-sm': {
        textAlign: 'max-sm:text-center',
    },
    '@sm': {
        width: 'sm:w-full',
    },
    '@md': {
        width: 'md:w-full',
    },
    '@lg': {
        width: 'lg:w-full',
    },
});

const Contents = [
    {
        title: '2024년 7월 30일 기준 주주명부',
        date: '2024-07-30',
        time: '1분 소요',
        link: '/ir/board/4',
    },
    {
        title: '2023년 5월 22일 기준 주주명부',
        date: '2023-05-22',
        time: '1분 소요',
        link: '/ir/board/3',
    },
];

const IrBoard = () => {
    return (
        <div className={contentOuter.class}>
            <Container>
                <div className={flexContainer.class}>
                    <div className={textBox.class}>
                        <div className={aContainer.class}>
                            <section className={TableSection.class}>
                                {Contents.map((content, index) => {
                                    return (
                                        <a className={TableContainer.class} href={content.link}>
                                            <div className='text-2xl font-regular bg-gradient-to-r bg-clip-text text-transparent from-phl-secondary-1 to-phl-secondary-2 pb-1'>
                                                {content.date}
                                            </div>
                                            <div className='text-xl font-light pb-3'>{content.title}</div>
                                            <div className='text-sm font-regular text-gray-800'>[{content.time}]</div>
                                        </a>
                                    );
                                })}
                            </section>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default IrBoard;
