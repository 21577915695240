import './App.css';
import Router from './Routes';
import { phClient } from './Utils';
phClient.capture({
    distinctId: 'test-id',
    event: 'test-event',
});
function App() {
    return <Router />;
}

export default App;
