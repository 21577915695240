import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { motion, Variants, useInView } from 'framer-motion';

const frequencies = [
    { value: 'monthly', label: '월간결제', priceSuffix: '/month' },
    { value: 'annually', label: '연간결제', priceSuffix: '/month' },
];

const variants: Variants = {
    offscreen: {
        opacity: 0,
        y: 50,
    },
    onscreen: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'spring',
            bounce: 0,
            duration: 1,
        },
    },
};

export const tiers = [
    {
        name: 'Pay as you go',
        id: 'tier-pay-as-you-go',
        href: '#',
        price: { monthly: '0 KRW~', annually: '0 KRW~' },
        description: '사용한 만큼 결제하세요',
        features: ['Business Plan에 포함된 모든 기능', '10GB/월 Free tier'],
        mostPopular: true,
        longBox: true,
    },
    {
        name: 'Free',
        id: 'tier-free',
        href: '#',
        price: { monthly: 'Free', annually: 'Free' },
        description: '취미, 연구용에 적합.',
        features: [
            'Cache TTL 최장 6시간',
            'Cache Rule 최대 6개',
            'WAF Rule 최대 5개',
            'DDoS Shield Standard',
            'OWASP Top 10 취약점 보안',
            '인증서 자동 관리',
            '월 대역폭 1TB 제공',
        ],
        mostPopular: false,
    },
    {
        name: 'Pro',
        id: 'tier-Pro',
        href: '#',
        price: { monthly: '90,000 KRW', annually: '72,000 KRW' },
        description: '소규모 서비스를 위한 요금제.',
        features: [
            'Cache TTL 최장 30일',
            'Cache Rule 최대 10개',
            'WAF Rule 최대 15개',
            'DDoS Shield Pro',
            'OWASP Top 10 취약점 보안',
            '인증서 자동 관리',
            '정적 리소스 자동 최적화',
            '높은 우선순위 네트워크 제공',
            '월 대역폭 5TB 제공',
        ],
        mostPopular: false,
    },
    {
        name: 'Business',
        id: 'tier-business',
        href: '#',
        price: { monthly: '500,000 KRW', annually: '400,000 KRW' },
        description: '중규모 서비스를 위한 요금제',
        features: [
            'Cache TTL 최장 45일',
            'Cache Rule 최대 30개',
            'WAF Rule 최대 50개',
            'DDoS Shield Advanced',
            'OWASP Top 10 취약점 보안',
            '인증서 자동 관리',
            '정적 리소스 자동 최적화',
            '높은 우선순위 네트워크 제공',
            '월 대역폭 50TB 제공',
        ],
        mostPopular: true,
    },
    {
        name: 'Enterprise',
        id: 'tier-enterprise',
        href: '#',
        price: { monthly: '별도 문의', annually: '별도 문의' },
        description: '대규모 서비스를 위한 요금제.',
        features: ['별도 문의'],
        mostPopular: false,
    },
];

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

export default function Example() {
    const [frequency, setFrequency] = useState(frequencies[0]);

    return (
        <div className='pt-28'>
            <div className='bg-white py-24 sm:py-32'>
                <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                    <div className='mx-auto max-w-4xl text-center'>
                        <h2 className='text-base font-semibold leading-7 text-phl-secondary-1'>서비스 가격</h2>
                        <p className='mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
                            모든 규모의 고객에 맞는 가격
                        </p>
                    </div>
                    <p className='mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600'>
                        귀하에게 적합한 요금제를 선택하세요.
                    </p>
                    <div className='mt-16 flex justify-center'>
                        <RadioGroup
                            value={frequency}
                            onChange={setFrequency}
                            className='grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200'>
                            <RadioGroup.Label className='sr-only'>결제 주기</RadioGroup.Label>
                            {frequencies.map((option) => (
                                <RadioGroup.Option
                                    key={option.value}
                                    value={option}
                                    className={({ checked }) =>
                                        classNames(
                                            checked ? 'bg-phl-secondary-1 text-white' : 'text-gray-500',
                                            'cursor-pointer rounded-full px-2.5 py-1'
                                        )
                                    }>
                                    <span>{option.label}</span>
                                </RadioGroup.Option>
                            ))}
                        </RadioGroup>
                    </div>
                    <motion.div
                        initial='offscreen'
                        whileInView='onscreen'
                        viewport={{ once: false, amount: 0 }}
                        variants={variants}>
                        <div className='isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4'>
                            {tiers.map((tier: any) =>
                                !tier.longBox ? (
                                    <div
                                        key={tier.id}
                                        className={classNames(
                                            tier.mostPopular ? 'ring-2 ring-phl-secondary-1' : 'ring-1 ring-gray-200',
                                            'rounded-3xl p-8'
                                        )}>
                                        <h3
                                            id={tier.id}
                                            className={classNames(
                                                tier.mostPopular ? 'text-phl-secondary-1' : 'text-gray-900',
                                                'text-lg font-semibold leading-8'
                                            )}>
                                            {tier.name}
                                        </h3>
                                        <p className='mt-4 text-sm leading-6 text-gray-600'>{tier.description}</p>
                                        <p className='mt-6 flex items-baseline gap-x-1'>
                                            <span className='text-4xl font-bold tracking-tight text-gray-900'>
                                                {tier.price[frequency.value]}
                                            </span>
                                            <span className='text-sm font-semibold leading-6 text-gray-600'>
                                                {frequency.priceSuffix}
                                            </span>
                                        </p>
                                        <a
                                            href='https://portal.phylaxis.net/signin'
                                            aria-describedby={tier.id}
                                            className={classNames(
                                                tier.mostPopular
                                                    ? 'bg-phl-secondary-1 text-white shadow-sm hover:bg-phl-secondary-2'
                                                    : 'text-phl-secondary-1 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                                                'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-phl-secondary-1 transition-all ease-in-out duration-150'
                                            )}>
                                            구매하기
                                        </a>
                                        <ul role='list' className='mt-8 space-y-3 text-sm leading-6 text-gray-600'>
                                            {tier.features.map((feature: any) => (
                                                <li key={feature} className='flex gap-x-3'>
                                                    <CheckIcon
                                                        className='h-6 w-5 flex-none text-phl-secondary-1'
                                                        aria-hidden='true'
                                                    />
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <div
                                        key={tier.id}
                                        className={classNames(
                                            tier.mostPopular ? 'ring-2 ring-phl-secondary-1' : 'ring-1 ring-gray-200',
                                            'rounded-3xl p-8 col-span-1 md:col-span-2 xl:col-span-4'
                                        )}>
                                        <div className='sm:flex flex-row justify-between'>
                                            <div className='sm:border-r border-gray-200 pr-10'>
                                                <h3
                                                    id={tier.id}
                                                    className={classNames(
                                                        tier.mostPopular ? 'text-phl-secondary-1' : 'text-gray-900',
                                                        'text-lg font-semibold leading-8'
                                                    )}>
                                                    {tier.name}
                                                </h3>
                                                <p className='mt-4 text-sm leading-6 text-gray-600'>
                                                    {tier.description}
                                                </p>
                                            </div>
                                            <div className='pl-5 flex flex-row align-middle'>
                                                <p className='mt-6 flex items-baseline gap-x-1'>
                                                    <span className='text-4xl font-bold tracking-tight text-gray-900'>
                                                        {tier.price[frequency.value]}
                                                    </span>
                                                    <span className='text-sm font-semibold leading-6 text-gray-600'>
                                                        {frequency.priceSuffix}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <ul role='list' className='mt-8 space-y-3 text-sm leading-6 text-gray-600'>
                                            {tier.features.map((feature: any) => (
                                                <li key={feature} className='flex gap-x-3'>
                                                    <CheckIcon
                                                        className='h-6 w-5 flex-none text-phl-secondary-1'
                                                        aria-hidden='true'
                                                    />
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                        <a
                                            href='https://portal.phylaxis.net/signin'
                                            aria-describedby={tier.id}
                                            className={classNames(
                                                tier.mostPopular
                                                    ? 'bg-phl-secondary-1 text-white shadow-sm hover:bg-phl-secondary-2'
                                                    : 'text-phl-secondary-1 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                                                'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-phl-secondary-1 transition-all ease-in-out duration-150'
                                            )}>
                                            문의하기
                                        </a>
                                    </div>
                                )
                            )}
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}
