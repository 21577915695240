import React, { useState, useRef } from "react";
import Navigation from "../Components/Navigation/Navigation";
import Content from "../Components/IntroContents/Content";
import { useStrictMediaQuery } from "../Utils/hooks";
import Footer from "../Components/Footer";
import AnnouncementDetail3 from "../Components/Announcement/Detail3";


const IrDefault = () => {
  return (
    <>
      <a id="top" />
      <Navigation />
      <AnnouncementDetail3 />
      <Footer />
    </>
  );
};

export default IrDefault;