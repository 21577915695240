import { Container } from "../Container2";
import { useId } from "react";
import { motion } from "framer-motion";
import ReactPixel from "react-facebook-pixel";
import { useTranslation } from "react-i18next";

function BackgroundIllustration(props: React.ComponentPropsWithoutRef<"div">) {
  let id = useId();

  return (
    <div {...props}>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-slow"
      >
        <path
          d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
          stroke="#D4D4D4"
          strokeOpacity="0.7"
        />
        <path
          d="M513 1025C230.23 1025 1 795.77 1 513"
          stroke="#2C46FF"
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id={`${id}-gradient-1`}
            x1="1"
            y1="513"
            x2="1"
            y2="1025"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#06b6d4" />
            <stop offset="1" stopColor="#06b6d4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-reverse-slower"
      >
        <path
          d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
          stroke="#2C46FF"
          strokeOpacity="0.7"
        />
        <path
          d="M913 513c0 220.914-179.086 400-400 400"
          stroke={`url(#${id}-gradient-2)`}
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id={`${id}-gradient-2`}
            x1="913"
            y1="513"
            x2="913"
            y2="913"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#06b6d4" />
            <stop offset="1" stopColor="#06b6d4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
const AnimatedText = ({
  text,
  delayPerWord = 0.1,
}: {
  text: string;
  delayPerWord?: number;
}) => {
  const words = text.split(" ");
  return (
    <div>
      {words.map((word: any, index: any) => (
        <motion.h2
          style={{ display: "inline-block", marginRight: "0.5rem" }}
          variants={perWordVar(index * delayPerWord)}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0 }}
        >
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {word}
          </h2>
        </motion.h2>
      ))}
    </div>
  );
};

export const getCookieValue = (key: string) => {
  let cookieKey = key + "=";
  let result = "";
  const cookieArr = document.cookie.split(";");

  for (let i = 0; i < cookieArr.length; i++) {
    if (cookieArr[i][0] === " ") {
      cookieArr[i] = cookieArr[i].substring(1);
    }

    if (cookieArr[i].indexOf(cookieKey) === 0) {
      result = cookieArr[i].slice(cookieKey.length, cookieArr[i].length);
      return result;
    }
  }
  return result;
};

const clickCta = () => {
  ReactPixel.track("ClickInquiry");
  window.location.href = "/inquiry";
};

const perWordVar = (delay: number) => {
  return {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: delay,
        type: "spring",
        stiffness: 100,
      },
    },
  };
};

export function Hero() {
  const { t } = useTranslation("");

  const containerVariants = (delay: number) => {
    return {
      hidden: { opacity: 0, y: 20 },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          delay: delay,
          duration: 0.8,
        },
      },
    };
  };

  return (
    <div className="overflow-hidden py-20 sm:py-32 lg:pb-32 xl:pb-36">
      <Container>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
          <div className="relative -mt-4 lg:col-span-7 lg:mt-0 xl:col-span-6">
            {/* <motion.h1
                initial="hidden"
                animate="visible"
                variants={containerVariants(0)}
              >
            <h1 className="text-4xl font-medium tracking-tight text-gray-900">
              안전한 인터넷 환경, <br />
              여기서 시작.
            </h1>
            </motion.h1> */}
            <AnimatedText text={t("index.slogan")} />
            <motion.p
              style={{ display: "inline-block", marginRight: "0.3rem" }}
              variants={perWordVar(1)}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0 }}
            >
              <p className="mt-4 text-lg leading-8 text-gray-400">
                {t("index.sub_slogan")}
              </p>
            </motion.p>
            <motion.a
              initial="hidden"
              whileInView="visible"
              variants={containerVariants(0.1)}
              viewport={{ once: false, amount: 0.2 }}
            >
              <a onClick={clickCta} style={{ cursor: "pointer" }}>
                <div className="sm:mb-8 flex sm:justify-start">
                  <div className="mt-8 relative rounded-full px-3 py-1 text-sm leading-6 text-black ring-1 ring-black/80 hover:ring-gray-300 transition-all ease-in-out duration-150 shadow-black">
                    {t("learn_more")}{" "}
                    <a className="font-semibold text-black" href="https://waap.phylaxis.net">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div>
              </a>
            </motion.a>
          </div>
          <div className="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
            <BackgroundIllustration className="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0" />
            <div className="-mx-4 lg:h-[448px] px-9 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:mx-0 h-[100px] lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32">
              <img
                src="/img/Emblem.svg"
                alt=""
                className="w-full h-full object-contain hidden lg:block"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Hero;
