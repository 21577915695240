import React, { useState, useRef } from "react";
import Navigation from "../Components/Navigation/Navigation";
import Content from "../Components/IntroContents/Content";
import { useStrictMediaQuery } from "../Utils/hooks";
import Footer from "../Components/Footer";
import AnnouncementDetail2 from "../Components/Announcement/Detail2";

const IrDefault = () => {
  return (
    <>
      <a id="top" />
      <Navigation />
      <AnnouncementDetail2 />
      <Footer />
    </>
  );
};

export default IrDefault;