import { ReceiptPercentIcon, SwatchIcon, ServerIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: '최대 97% 트래픽 비용 절감.',
    description: '캐싱 기능 및 DDoS 방어 기능을 통해 트래픽 비용을 최대 97%까지 절감할 수 있습니다. *1',
    icon: ReceiptPercentIcon,
  },
  {
    name: '유연한 캐싱 정책.',
    description: '원하는 경로 캐싱은 물론, 접속자의 정보를 이용한 캐싱 정책을 설정할 수 있습니다.',
    icon: SwatchIcon,
  },
  {
    name: '응답속도 향상.',
    description: 'Phylaxis 에지 네트워크를 통해 전 세계에 캐시 서버를 배치하여 응답속도를 향상시킬 수 있습니다.',
    icon: ServerIcon,
  },
]

export default function Caching() {
  return (
    <div className="bg-white py-24">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
            <div className="lg:row-start-2 lg:max-w-md">
              <div className="hidden sm:mb-5 sm:flex sm:justify-start">
                <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-white ring-1 ring-white/80 hover:bg-white/10 transition-all ease-in-out duration-150 shadow">
                  우리 회사는 얼마나 아낄 수 있을까요?{' '}
                  <a href="/calculator" className="font-semibold text-blue-400">
                    <span className="absolute inset-0" aria-hidden="true" />
                    계산해보기 <span aria-hidden="true">&rarr;</span>
                  </a>
                </div>
              </div>
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                압도적인 비용 절감
                <br />
                지금 시작해보세요.
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                날이 갈수록 늘어나는 비용에 지치셨나요? <br />
                Phylaxis WAAP와 함께 CDN, WAF, DDoS 방어, SSL 인증서 자동 발행 등의 기능을 합리적인 가격에 이용해보세요.
              </p>
            </div>
            <img
              src="/img/content/head01.jpeg"
              alt="Product screenshot"
              className="relative -z-20 min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:w-[64rem] lg:max-w-none opacity-30"
            />
            <div className="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10">
              <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt className="ml-9 inline-block font-semibold text-white">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-phl-secondary-2" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div
            className="pointer-events-none absolute left-12 top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu"
            aria-hidden="true"
          >
            <div
              className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-25"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
