import { CheckIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'HTTP/2 Rapid Reset 공격',
    description: 'HTTP/2 Rapid Reset 공격은 HTTP/2의 RST_STREAM 프레임을 이용한 공격입니다. 이를 통해 서버의 리소스를 고갈시킵니다.',
  },
  {
    name: 'Slowloris 공격',
    description: 'Slowloris 공격은 헤더를 보내지 않고, 연결을 유지하는 공격입니다. 이를 통해 서버의 리소스를 고갈시킵니다.'
  },
  {
    name: 'SYN Flood 공격',
    description: 'SYN Flood 공격은 TCP의 3-way handshake를 이용한 공격입니다. 이를 통해 서버의 리소스를 고갈시킵니다.',
  },
  {
    name: 'DNS Amplification 공격',
    description: 'UDP 공격 중 하나로, DNS 서버를 이용한 공격입니다. 이를 통해 서버의 리소스(특히 대역폭)를 고갈시킵니다.'
  },
  {
    name: 'NTP Amplification 공격',
    description: 'UDP 공격 중 하나로, NTP 서버를 이용한 공격입니다. 이를 통해 서버의 리소스(특히 대역폭)를 고갈시킵니다.'
  },
  {
    name: 'RUDY 공격',
    description: 'RUDY 공격은 POST 요청을 이용한 공격입니다. 이를 통해 서버의 리소스를 고갈시킵니다.',
  },
  {
    name: 'HULK 공격',
    description: 'HULK 공격은 GET 요청의 URI를 이용한 공격입니다. 이를 통해 서버의 리소스를 고갈시킵니다.',
  },
  {
    name: '이 외 다수',
    description: '실제 브라우저를 통해 정상 접속하는 것과 같은 패턴을 이용한 공격도 대응할 수 있습니다.'
  }
]

export default function Essential() {
  return (
    <a id="WAF">
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-phl-secondary-1">DDoS 보안 지원</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">실시간으로 대응합니다.</p>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Phylaxis의 Edge-Network에서 감지되는 악성 트래픽은 실시간으로 학습되고, 이를 통해 신 유형 공격에도 문제없이 대응할 수 있습니다.
              </p>
            </div>
            <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="font-semibold text-gray-900">
                    <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-phl-secondary-1" aria-hidden="true" />
                    {feature.name}
                  </dt>
                  <dd className="mt-2">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </a>
  )
}
