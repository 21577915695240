export default function Hero() {
  return (
          <div className="bg-white py-24 sm:py-32">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                  <div className="mx-auto max-w-2xl lg:mx-0">
                      <p className="text-base font-semibold leading-7 text-phl-secondary-1">Inquiry</p>
                      <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Phylaxis 도입 문의</h2>
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                          Phylaxis는 여러분과의 협업을 기다리고 있어요.
                      </p>
                  </div>
              </div>
          </div>
  )
}
