import React, { useState, useRef } from "react";
import Navigation from "../Components/Navigation/Navigation";
import Content from "../Components/IntroContents/Content";
import { useStrictMediaQuery } from "../Utils/hooks";
import Hero from "../Components/Brochure/Hero";
import Form from "../Components/Brochure/Form";
import Helmet from "react-helmet";

export const Brochure = () => {
    return (
        <>
            <Helmet>
                <title>Phylaxis: 브로셔 신청</title>
                <meta name="og:title" content="Phylaxis: 브로셔 신청" />
                <meta name="og:description" content="Phylaxis 브로셔를 신청하세요" />
                <meta name="description" content="Phylaxis 브로셔를 신청하세요" />
            </Helmet>
            <a id="top" />
            <Navigation />
            <Hero />
            <Form />
        </>
    );
}
