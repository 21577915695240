import React from "react";
import { Container } from "../Container";
import {
  BsHddNetwork,
  BsShieldShaded,
  BsShieldLock,
  BsSave,
} from "react-icons/bs";
import "tailwindcss/tailwind.css";
import styled, { keyframes } from "styled-components";
import { Union, handleOpenExternal, tw } from "../../Utils";
import { useStrictMediaQuery } from "../../Utils/hooks";
import { ButtonTransparencyWhite } from "../Buttons";

const contentBox = tw.style({
  alignItems: "items-center",
  backgroundColor: "bg-white",
  overflow: "overflow-hidden",
  paddingY: "py-16",
  boxShadow: "shadow-xl",
  paddingX: "px-24",
  "@max-md": {
    paddingX: "max-md:px-12",
    paddingY: "max-md:py-12",
  },
  "@max-sm": {
    paddingX: "max-sm:px-6",
    paddingY: "max-sm:py-12",
  },
});

const 도움이 = tw.style({
  color: "text-slate-900",
  marginBottom: "mb-3",
  fontWeight: "font-semibold",
  fontSize: "text-2xl",
  "@max-md": {
    fontSize: "max-md:text-xl",
    marginBottom: "max-md:mb-1",
  },
  "@max-sm": {
    fontSize: "max-sm:text-lg",
    marginBottom: "max-sm:mb-0",
  },
});

const 지금영업팀으로 = tw.style({
  color: "text-slate-900",
  fontWeight: "font-bold",
  fontSize: "text-4xl",
  "@max-md": {
    fontSize: "max-md:text-3xl",
  },
  "@max-sm": {
    fontSize: "max-sm:text-2xl",
  },
});

const RoundedBox = () => {
  return (
    <div className={contentBox.class}>
      <Container>
        <div
          className={Union(
            "flex flex-row max-lg:flex-col bg-gradient-to-r bg-slate-100 rounded-3xl md:p-20 max-md:p-12 max-sm:p-10"
          )}
        >
          <div className={Union("items-left max-lg:mb-6")}>
            <h1 className={도움이.class}>도움이 필요하신가요?</h1>
            <h2 className={지금영업팀으로.class}>
              지금 영업팀으로 문의하세요.
            </h2>
          </div>
          <div
            className={Union("flex items-center w-1/5 ml-auto max-lg:w-full")}
          >
            <ButtonTransparencyWhite
              size="md-bold"
              text="문의하기"
              className="w-full"
              onClick={() =>
                handleOpenExternal("https://phylaxis.channel.io/home")
              }
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RoundedBox;
