import { createTools, type Tailwindest } from 'tailwindest';
import { PostHog } from 'posthog-node';

export const phClient = new PostHog('phc_xJglQjPj6jjvAuMM22UUBwYQz7m2paPUb58kprOjUvi');

export const Union = (...classes: Array<string | boolean | undefined>): string => {
    return classes.filter(Boolean).join(' ');
};

export const handleOpenExternal = (url: string): void => {
    window.open(url, '_blank', 'noopener, noreferrer');
};

/* Tailwindest */
type TailwindCustom = Tailwindest<{
    color: 'phl-primary' | 'phl-secondary-1' | 'phl-secondary-2' | 'phl-secondary-3';
}>;

export const tw = createTools<TailwindCustom>();
