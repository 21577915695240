import { useState } from 'react'
import CountUp from 'react-countup';

export default function CostSavingsCalculator() {
    const [currentCost, setCurrentCost] = useState('')
    const [savings, setSavings] = useState(0)

    const handleSubmit = (e: any) => {
        e.preventDefault()
        const cost = parseInt(currentCost.replace(/,/g, ''))
        const calculatedSavings = Math.round(cost * 0.53)
        setSavings(calculatedSavings)
    }

    return (
        <div className="bg-phl-primary">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-lg bg-white rounded-2xl shadow-lg p-8">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-8">
                        Phylaxis WAAP로<br />절약해보세요!
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label htmlFor="cost" className="block text-sm font-medium text-gray-700">
                                현재 월 Data Transfer 비용 (원)
                            </label>
                            <div className="relative mt-1 rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="cost"
                                    id="cost"
                                    className="block w-full rounded-md border-gray-300 pr-12 focus:border-blue-500 focus:ring-blue-500 sm:text-sm m-4"
                                    placeholder="금액 입력"
                                    value={currentCost}
                                    onChange={(e) => setCurrentCost(e.target.value)}
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <span className="text-gray-500 sm:text-sm">KRW</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="w-full inline-flex justify-center items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                절약액 계산하기
                            </button>
                        </div>
                    </form>

                    {savings > 0 && (
                        <div className="mt-12 text-center">
                            <h3 className="text-5xl font-extrabold text-blue-600 mb-4">
                                약 <CountUp end={savings} separator="," duration={0.5} />원
                            </h3>
                            <p className="text-xl text-gray-600">을 아낄 수 있어요!</p>
                            <p>평균 절감액 53% 기준</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}