import React, { useState, useRef } from 'react';
import Navigation from '../Components/Navigation/Navigation';
import Content from '../Components/IntroContents/Content';
import { useStrictMediaQuery } from '../Utils/hooks';
import Footer from '../Components/Footer';
import Hero from '../Components/IntroContents/Hero';
import DDoSStats from '../Components/IntroContents/DDoSStats';
import Mission from '../Components/IntroContents/Mission';
import { Helmet } from 'react-helmet';

const IntroPage = () => {
    return (
        <>
            <Helmet>
                <title>Phylaxis: 기업 소개</title>
                <meta name='og:title' content='Phylaxis: 기업 소개' />
                <meta name='og:description' content='Phylaxis 기업 소개' />
                <meta name='description' content='Phylaxis 기업 소개' />
            </Helmet>
            <a id='top' />
            <Navigation />
            <Hero />
            <DDoSStats />
            <Mission />
            <Content />
            <hr className='h-px bg-gray-200 border-0 dark:bg-gray-200'></hr>
            <Footer />
        </>
    );
};

export default IntroPage;
