import React, { useState, useRef } from 'react';
import Navigation from '../Components/Navigation/Navigation';
import Content from '../Components/IntroContents/Content';
import { useStrictMediaQuery } from '../Utils/hooks';
import Footer from '../Components/Footer';
import RoundedBox from '../Components/IndexContents/RoundedBox';
import EntJumbo from '../Components/EnterpriseContents/Jumbo';
import EntBoxes from '../Components/EnterpriseContents/Boxes';
import Helmet from 'react-helmet';

const RequestEnterprise = () => {
    return (
        <>
            <Helmet>
                <title>Phylaxis: 도입 문의</title>
                <meta name='og:title' content='Phylaxis: 도입 문의' />
                <meta name='og:description' content='Phylaxis 도입 신청을 하세요' />
                <meta name='description' content='Phylaxis 도입 신청을 하세요' />
            </Helmet>
            <Navigation />
            <a id='top'>
                <EntJumbo />
            </a>
            <EntBoxes />
            <Footer />
        </>
    );
};

export default RequestEnterprise;
